import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Checkbox as MUICheckbox } from '@material-ui/core';
import cn from 'classnames';
import { useTheme } from '../../../theme';
var Checkbox = React.forwardRef(function (_a, ref) {
    var _b;
    var className = _a.className, required = _a.required, restProps = __rest(_a, ["className", "required"]);
    var css = useTheme('BaseComponents').Checkbox;
    return (React.createElement(MUICheckbox, __assign({ ref: ref, classes: {
            root: cn(css.checkbox, className, (_b = {}, _b[css.required] = required, _b)),
            checked: css.checked,
            disabled: css.disabled
        } }, restProps)));
});
export default Checkbox;
