import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import SearchForm from '../../SearchForm';
import Checkout from '../../Checkout';
import ResultsError from '../../ResultsError';
import { DelayedPromoLoader } from '../../PromoLoader';
import FastSearch from './FastSearch/FastSearch';
import MetaRedirect from './MetaRedirect/MetaRedirect';
import { useConfig } from '../../context';
import FindOrder from '../../Checkout/components/Checkout/FindOrder/FindOrder';
import Page404 from '../../Page404/Page404';
import CreateOrder from './CreateOrder/CreateOrder';
import { API_DATE_FORMAT, format, initI18n, isRequireCurrencyParam } from '../../utils';
import FareLock from '../../FareLock/';
import { useFareLock } from '../../FareLock/hooks';
import RestoreOrder from './RestoreOrder/RestoreOrder';
import { useTheme } from '../../theme';
import { OverrideComponent } from '../../renderProps';
import Schedule from '../../Schedule';
import { useRunSearchMutation, useSearchResultsQuery } from '@websky/graphql';
import { useSchedule } from '../../Schedule/hooks';
import { CURRENCY_KEY, set } from '../../cache';
import getOrderDataLayer, { createDataLayer } from '../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../DataLayerAnalytics/OrderDataLayer/types';
import { initTagManager } from '../../DataLayerAnalytics/tagManager';
import { ResultsWrapper } from '../../Results/components/ResultsWrapper/ResultsWrapper';
import { extractPromo, hasPromo } from './FastSearch/utils';
import { ApolloError } from 'apollo-boost';
import { EmptySearchResultSegmentsError } from './errors';
createDataLayer(OrderType.SearchResults);
initI18n('Title');
var Inner = function (_a) {
    var history = _a.history;
    var t = useTranslation().t;
    var css = useTheme('Inner').Inner;
    var _b = __read(useState(false), 2), isRedirectFromMeta = _b[0], setIsRedirectFromMeta = _b[1];
    var _c = __read(useState(null), 2), order = _c[0], setOrder = _c[1];
    var _d = __read(useState(), 2), results = _d[0], setResults = _d[1];
    var _e = __read(useState(null), 2), error = _e[0], setError = _e[1];
    var _f = __read(useState(false), 2), isLoading = _f[0], setIsLoading = _f[1];
    var _g = __read(useState(false), 2), orderLoading = _g[0], setOrderLoading = _g[1];
    var _h = useConfig(), searchFormURL = _h.Engine.searchFormURL, _j = _h.global, iataCode = _j.companyInfo.iataCode, allowPromoCodes = _j.allowPromoCodes;
    var _k = __read(useRunSearchMutation(), 1), searchMutation = _k[0];
    var resultsQuery = useSearchResultsQuery({ fetchPolicy: 'no-cache', skip: true });
    var _l = __read(useState(undefined), 2), promoCode = _l[0], setPromoCode = _l[1];
    React.useEffect(function () {
        initTagManager();
    }, []);
    var handleOrderLoaded = useCallback(function (order) {
        setOrder(order);
    }, [order, setOrder]);
    var orderLoadingHandler = useCallback(function (orderLoading) {
        setOrderLoading(orderLoading);
    }, []);
    var _m = useFareLock(order, handleOrderLoaded), price = _m.price, handleChangeFareLock = _m.handleChangeFareLock, isSelected = _m.isSelected, fareLockService = _m.fareLockService, loading = _m.loading;
    var handleRenderFareLock = useCallback(function () {
        if (order && fareLockService && !orderLoading) {
            getOrderDataLayer(OrderType.Checkout).setOrder(order);
            return (React.createElement(FareLock, { freezeUntil: fareLockService.freezeUntil, price: price, isActive: isSelected, onChange: handleChangeFareLock }));
        }
        return null;
    }, [price, isSelected, handleChangeFareLock, order]);
    var _o = __read(useSchedule(), 2), getSchedule = _o[0], _p = _o[1], schedule = _p.schedule, defaultParameters = _p.defaultParameters, availableDates = _p.availableDates, scheduleLoading = _p.isLoading;
    var dismissMetaWarning = useCallback(function () {
        setIsRedirectFromMeta(false);
    }, []);
    var onError = useCallback(function (e) {
        var _a, _b;
        var error = new Error();
        error.name = t('It looks like something went wrong');
        error.message = t('Please reload the page or start a new search');
        if (e instanceof ApolloError && ((_b = (_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.originalError)) {
            error = e.graphQLErrors[0].originalError;
        }
        else if (e instanceof Error) {
            error = e;
        }
        setError(error);
        console.error(e);
    }, [setError]);
    var loadResults = useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setOrder(null);
                    setIsLoading(true);
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, resultsQuery.refetch({ id: id })];
                case 2:
                    data = (_e.sent()).data;
                    if (!((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.SearchResult) === null || _a === void 0 ? void 0 : _a.searchParameters) === null || _b === void 0 ? void 0 : _b.segments) === null || _c === void 0 ? void 0 : _c.length)) {
                        throw new EmptySearchResultSegmentsError();
                    }
                    setResults((_d = data === null || data === void 0 ? void 0 : data.SearchResult) !== null && _d !== void 0 ? _d : null);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _e.sent();
                    onError(error_1);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [resultsQuery.refetch, setOrder, setIsLoading, onError]);
    var startSearch = useCallback(function (params, replace) {
        if (replace === void 0) { replace = true; }
        return __awaiter(void 0, void 0, void 0, function () {
            var data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        setError(null);
                        if (params.currency) {
                            set(CURRENCY_KEY, params.currency);
                        }
                        if (replace) {
                            history.replace('/results');
                        }
                        else {
                            history.push('/results');
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, searchMutation({
                                variables: {
                                    params: __assign(__assign({}, params), { currency: isRequireCurrencyParam() ? params.currency : undefined })
                                }
                            })];
                    case 2:
                        data = _a.sent();
                        if (data.errors) {
                            throw new Error(data.errors[0].message);
                        }
                        else if (data.data.RunGeneralSearch && data.data.RunGeneralSearch.id) {
                            if (replace) {
                                history.push('/results/' + data.data.RunGeneralSearch.id);
                            }
                            else {
                                history.replace('/results/' + data.data.RunGeneralSearch.id);
                            }
                            loadResults(data.data.RunGeneralSearch.id);
                        }
                        else {
                            throw new Error('No results ID');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        setError({
                            name: t('It looks like something went wrong'),
                            message: "".concat(t('Please reload the page or start a new search'))
                        });
                        console.error(error_2);
                        setIsLoading(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }, []);
    var onOrderRecieved = function (order, replace) {
        if (replace === void 0) { replace = false; }
        if (order) {
            setOrder(order);
        }
        history.push("/booking/".concat(order.id));
    };
    var redirectToPayment = function (order) {
        if (order) {
            setOrder(order);
            history.push("/booking/".concat(order.id, "/payment"));
        }
    };
    var onMetasearchResultsRedirect = function (resultsId) {
        setIsRedirectFromMeta(true);
        history.replace('/results/' + resultsId);
    };
    var onScheduleSearch = useCallback(function (parameters, date, reload) {
        if (date) {
            getSchedule(parameters, date);
        }
        else {
            var path = "/schedule/".concat(parameters.departure, "/").concat(parameters.arrival);
            if (history.location.pathname !== path) {
                history.push(path);
                if (reload) {
                    window.location.reload();
                }
            }
        }
    }, [history, getSchedule]);
    var isScheduleAvailable = ['ZZ', 'YC', 'DV', 'KV', 'Y7'].includes(iataCode);
    return (React.createElement("div", { style: { flexBasis: '100%' }, className: css.inner },
        React.createElement(Helmet, null,
            React.createElement("title", null,
                t('Title:companyName'),
                " \u2013 ",
                t('Title:Find tickets'))),
        React.createElement(DelayedPromoLoader, { loading: isLoading || scheduleLoading },
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/", exact: true, render: function () {
                        return searchFormURL ? ((window.location.href = searchFormURL)) : (React.createElement(OverrideComponent, { componentProps: { onSubmit: startSearch, promoCode: promoCode }, component: { SearchForm: SearchForm } }));
                    } }),
                React.createElement(Route, { path: "/search/:departure/:arrival", exact: true, render: function (routeProps) { return (React.createElement(OverrideComponent, { componentProps: {
                            onSubmit: startSearch,
                            defaultParameters: {
                                currency: null,
                                segments: [
                                    {
                                        arrival: {
                                            iata: routeProps.match.params['arrival'],
                                            city: null,
                                            name: null
                                        },
                                        departure: {
                                            iata: routeProps.match.params['departure'],
                                            city: null,
                                            name: null
                                        },
                                        date: format(new Date(), API_DATE_FORMAT)
                                    }
                                ],
                                passengers: null,
                                promotionCode: null,
                                ffpMode: null
                            }
                        }, component: { SearchForm: SearchForm } })); } }),
                React.createElement(Route, { path: "/restore/:orderId/:token", exact: true, render: function () { return (React.createElement(RestoreOrder, { onOrderReceived: onOrderRecieved })); } }),
                React.createElement(Route, { path: ['/find/:id?/:secret?', '/find/:id?/:secret?/submit'], exact: true, render: function (routeProps) {
                        if (routeProps.match.path.includes('submit')) {
                            return React.createElement(FindOrder, { onSuccess: onOrderRecieved, callSubmit: true });
                        }
                        else {
                            return React.createElement(FindOrder, { onSuccess: onOrderRecieved });
                        }
                    } }),
                React.createElement(Route, { path: ['/payment/:id?/:secret?', '/payment/:id?/:secret?/submit'], exact: true, render: function (routeProps) {
                        var callSubmit = routeProps.match.path.includes('submit');
                        return React.createElement(FindOrder, { onSuccess: redirectToPayment, callSubmit: callSubmit });
                    } }),
                React.createElement(Route, { path: "/metasearch/:token", exact: true, render: function () { return (React.createElement(MetaRedirect, { onResultsRedirect: onMetasearchResultsRedirect, onSuccess: onOrderRecieved })); } }),
                React.createElement(Route, { path: "/results", exact: true, render: function () {
                        if (error) {
                            return (React.createElement(React.Fragment, null,
                                React.createElement(OverrideComponent, { componentProps: { onSubmit: startSearch }, component: { SearchForm: SearchForm } }),
                                React.createElement(ResultsError, { header: error.name, body: error.message })));
                        }
                        return !error && !isLoading ? React.createElement(Redirect, { to: "/" }) : null;
                    } }),
                React.createElement(Route, { path: "/search/:fastSearch", exact: true, render: function (props) { return (React.createElement(OverrideComponent, { componentProps: {
                            startSearch: startSearch,
                            fastSearch: props.match.params.fastSearch,
                            redirect: React.createElement(Redirect, { to: "/" })
                        }, component: { FastSearch: FastSearch } })); } }),
                React.createElement(Route, { path: "/results/create-order/:flightIds+", render: function (props) { return (React.createElement(CreateOrder, { flightsIds: props.match.params.flightIds, redirectOnFail: React.createElement(Redirect, { to: '/' }), onSuccess: onOrderRecieved })); } }),
                React.createElement(Route, { path: "/results/:id(\\d+/?)+", render: function (props) {
                        return (React.createElement(React.Fragment, null,
                            React.createElement(ResultsWrapper, { order: order, loading: loading, renderFareLock: handleRenderFareLock, showMetaWarning: isRedirectFromMeta, onMetaWarningDismiss: dismissMetaWarning, onOrderLoaded: handleOrderLoaded, onOrderLoading: orderLoadingHandler, searchResults: results, errors: error ? [error] : null, startNewSearch: startSearch, onProceed: onOrderRecieved, resultId: props.match.params.id, loadResults: loadResults, isLoading: isLoading, error: error })));
                    } }),
                React.createElement(Route, { path: "/booking/:id(\\d+/?)+", render: function (props) { return (React.createElement(React.Fragment, null,
                        React.createElement(Helmet, null,
                            React.createElement("title", null,
                                t('Title:companyName'),
                                " \u2013 ",
                                t('Title:Checkout order'))),
                        React.createElement(Checkout, { startNewSearch: startSearch, orderId: props.match.params.id, order: order }))); } }),
                isScheduleAvailable && (React.createElement(Route, { path: "/schedule", exact: true, render: function () {
                        if (scheduleLoading) {
                            return null;
                        }
                        return React.createElement(Schedule, { onScheduleSearch: onScheduleSearch });
                    } })),
                isScheduleAvailable && (React.createElement(Route, { path: "/schedule/:departure([A-Z]{3,3})/:arrival([A-Z]{3,3})", render: function (props) {
                        var _a;
                        var params = props.match.params;
                        var segment = (_a = defaultParameters === null || defaultParameters === void 0 ? void 0 : defaultParameters.segments) === null || _a === void 0 ? void 0 : _a[0];
                        var isOtherCities = segment &&
                            (segment.departure.iata !== params.departure ||
                                segment.arrival.iata !== params.arrival);
                        if ((!isLoading && !scheduleLoading && !schedule) || isOtherCities) {
                            getSchedule({ departure: params.departure, arrival: params.arrival });
                            return null;
                        }
                        if (scheduleLoading) {
                            return null;
                        }
                        return (React.createElement(Schedule, { defaultParameters: defaultParameters, schedule: schedule, availableDates: availableDates, onStartSearch: startSearch, onScheduleSearch: onScheduleSearch }));
                    } })),
                React.createElement(Route, { path: "/:fastSearch", exact: true, render: function (props) {
                        var searchParams = props.match.params.fastSearch;
                        if (allowPromoCodes && hasPromo(searchParams)) {
                            setPromoCode(extractPromo(searchParams));
                        }
                        return React.createElement(Redirect, { to: "/" });
                    } }),
                React.createElement(Page404, { onClick: function () { return (window.location.href = '/'); } })))));
};
export default withRouter(Inner);
