import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { forwardRef, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Datepicker } from '../../Datepicker/components/Datepicker';
import { Provider as PriceMatrixProvider } from '../../PriceMatrix/theme';
import { priceGraphTheme, priceMatrixTheme } from '../theme';
import { RouteType } from '../../PriceGraph/types';
import { DialogAdapter } from './DialogAdapter/DialogAdapter';
import { format, initI18n, mergeCSS, MOBILE_MIN_WIDTH, removeDotsFromDate, TABLET_MIN_WIDTH } from '../../utils';
import Button from '../../Button/Button';
import { ThemeProvider, useTheme } from '../../theme';
import DummyPriceGraph from '../../Results/components/Results/Calendar/DummyPriceGraph/DummyPriceGraph';
import { Money } from '../../Money';
import LinearProgress from '@material-ui/core/LinearProgress';
import EmptyDatesOverlay from './EmptyDatesOverlay/EmptyDatesOverlay';
import { useRenderers } from '../../renderProps';
import { DatepickerTab } from '../../SearchForm/components/SearchForm/Segments/Segment/Datepicker/Datepicker';
import { isMultiCity } from '../../SearchForm/store/segments/selectors';
import { Currency } from '../../enums';
initI18n('SearchFormDatepicker');
var TabTypes;
(function (TabTypes) {
    TabTypes[TabTypes["PlainCalendar"] = 0] = "PlainCalendar";
    TabTypes[TabTypes["PriceGraph"] = 1] = "PriceGraph";
    TabTypes[TabTypes["RoundtripMatrix"] = 2] = "RoundtripMatrix";
})(TabTypes || (TabTypes = {}));
var calendarIcon = (React.createElement("svg", { width: "18", height: "20", viewBox: "0 0 18 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.01 2.9 0.01 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM4 9V11H6V9H4ZM10 9H8V11H10V9ZM14 9V11H12V9H14ZM2 18H16V7H2V18Z", fill: "#A5A5A5" })));
var graphIcon = (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6 16H10V0H6V16ZM0 16H4V8H0V16ZM12 16V5H16V16H12Z", fill: "#A5A5A5" })));
var PriceGraph = React.lazy(function () {
    return import('../../PriceGraph/components/PriceGraph');
});
var PriceMatrix = React.lazy(function () {
    return import('../../PriceMatrix/components/PriceMatrix');
});
var SearchFormDatepicker = React.memo(forwardRef(function (props, ref) {
    var _a, _b, _c, _d;
    var _e;
    var t = useTranslation('SearchFormDatepicker').t;
    var _f = __read(useState(TabTypes.PlainCalendar), 2), tab = _f[0], setTab = _f[1], _g = __read(useState(false), 2), isOpen = _g[0], toggleOpen = _g[1], outDate = props.selectedDates && props.selectedDates.length ? new Date(props.selectedDates[0]) : null, returnDate = props.selectedDates && props.selectedDates.length > 1 ? new Date(props.selectedDates[1]) : null, isTabDisplay = props.showPriceMatrix || props.showPriceGraph, isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 }), isNarrowDesktop = useMediaQuery({ maxWidth: 1160 });
    var isMultyCity = useSelector(isMultiCity);
    var _h = useRenderers(), renderAdaptiveSearchFormDatepickerControls = _h.renderAdaptiveSearchFormDatepickerControls, overridedRenderMoneyAmount = _h.renderMoneyAmount;
    var refEl = useRef(null);
    var contentRef = React.createRef();
    var onClose = function () {
        toggleOpen(false);
        props.clearOpenDate && props.clearOpenDate();
        if (props.onClose) {
            props.onClose();
        }
    };
    var onDone = function () {
        toggleOpen(false);
        if (props.onDone) {
            props.onDone();
        }
    };
    var open = function () {
        var _a;
        toggleOpen(true);
        (_a = props.onOpen) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    var onSelect = function (date) {
        if (props.onSelect) {
            props.onSelect(date, props.routeType === 'return');
        }
        if (props.closeAfterSelectReturnDate && props.closeAfterSelectReturnDate(date)) {
            toggleOpen(false);
        }
    };
    var searchFormDatepickerTheme = useTheme('SearchForm').Datepicker;
    var _j = useTheme('SearchFormDatepicker'), datepickerTheme = _j.SearchFormDatepickerStyles, defaultDatepickerTheme = __rest(_j, ["SearchFormDatepickerStyles"]);
    var showOverlay = false;
    if (props.routeType === DatepickerTab.Outbound && props.pricesTo === null) {
        showOverlay = true;
    }
    else if (props.routeType === DatepickerTab.Return && (props.pricesTo === null || props.pricesBack === null)) {
        showOverlay = true;
    }
    if (!isMobile) {
        showOverlay = false;
    }
    var pricesLoaded = function () {
        if (props.routeType === 'outbound' && Object.keys(props.pricesTo).length) {
            return true;
        }
        else if (props.routeType === 'return' &&
            Object.keys(props.pricesTo).length &&
            Object.keys(props.pricesBack).length) {
            return true;
        }
        return false;
    };
    var datepickerStyles = useTheme('Datepicker');
    var legendStyles = useTheme('PriceGraph').LegendsStyles;
    var datePickerWithPricesTheme = {
        Datepicker: __assign(__assign({}, defaultDatepickerTheme), { WeeklyHeaderStyles: __assign(__assign({}, defaultDatepickerTheme.WeeklyHeaderStyles), { dayOfWeekName: defaultDatepickerTheme.WeeklyHeaderStyles.dayOfWeekName_withPrices }), MonthStyles: __assign(__assign({}, defaultDatepickerTheme.MonthStyles), { week: defaultDatepickerTheme.MonthStyles.week_withPrice }), MonthsWrapperStyles: __assign(__assign({}, defaultDatepickerTheme.MonthsWrapperStyles), { monthsList: defaultDatepickerTheme.MonthsWrapperStyles.monthsList_withPrice }), DayStyles: __assign(__assign({}, defaultDatepickerTheme.DayStyles), { day: defaultDatepickerTheme.DayStyles.day_withPrice, day_endHover: defaultDatepickerTheme.DayStyles.day_endHover_withPrice, day_startHover: defaultDatepickerTheme.DayStyles.day_startHover_withPrice }) })
    };
    var customStyles = {
        Datepicker: __assign(__assign({}, defaultDatepickerTheme), { DayStyles: __assign(__assign({}, defaultDatepickerTheme.DayStyles), { day: props.datepickerAlternativeHighlighting
                    ? datepickerTheme.day
                    : defaultDatepickerTheme.DayStyles.day }), MonthsWrapperStyles: __assign(__assign({}, defaultDatepickerTheme.MonthsWrapperStyles), { wrapper: cn(defaultDatepickerTheme.MonthsWrapperStyles.wrapper, datepickerTheme.wrapper), nav_prev: cn(defaultDatepickerTheme.MonthsWrapperStyles.nav_prev, datepickerTheme.nav_prev), nav_next: cn(defaultDatepickerTheme.MonthsWrapperStyles.nav_next, datepickerTheme.nav_next) }) })
    };
    var defaultStyles = {
        Datepicker: __assign({}, datepickerStyles)
    };
    // todo: react.memo does not make deep compare, if we want to memoise mergedTheme, we can pass JSON.stringify(defaultStyles), JSON.strignify(customStyles) do deps array
    var mergedTheme = React.useMemo(function () { return mergeCSS(defaultStyles, props.useThemeWithPrices ? datePickerWithPricesTheme : customStyles); }, [defaultStyles, customStyles]);
    var renderMoneyAmount = function (amount, currency) {
        return amount.toString().length > 6
            ? t('{{amount}}{{k}}.', { amount: Math.floor(amount / 1000), k: currency === Currency.RUB ? 'т' : 'k' })
            : amount;
    };
    var dayWithPrice = function (date) {
        var _a;
        var _b, _c, _d;
        var compareString = date.toISOString();
        var price = null;
        var pricesMap = props.routeType === 'outbound' ? props.pricesTo : props.pricesBack;
        var hasFlight = (_b = pricesMap === null || pricesMap === void 0 ? void 0 : pricesMap[compareString]) === null || _b === void 0 ? void 0 : _b.hasFlight;
        if (pricesMap && pricesMap[compareString] && ((_d = (_c = pricesMap[compareString]) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.amount)) {
            price = pricesMap[compareString].price;
        }
        return (React.createElement("div", { className: cn((_a = {}, _a[defaultDatepickerTheme.DayStyles.day_hasPrice] = price || hasFlight, _a)) },
            date.getDate(),
            price && (React.createElement(Money, { moneyClassName: cn(defaultDatepickerTheme.DayStyles.day__price, searchFormDatepickerTheme.day__price), money: price, amountRenderer: overridedRenderMoneyAmount || renderMoneyAmount }))));
    };
    var handleOverlayButtonClick = React.useCallback(function () {
        setTab(TabTypes.PlainCalendar);
    }, []);
    var renderTabletFooter = useCallback(function (_a) {
        var _b, _c;
        var notActive = _a.notActive, onClear = _a.onClear, isDoneable = _a.isDoneable, onDone = _a.onDone;
        return (React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: datepickerTheme.footer__buttons },
                React.createElement(Button, { variant: "flat", size: "small", className: classnames(datepickerTheme.footer__clear, (_b = {},
                        _b[datepickerTheme.footer__clear_notActive] = !notActive,
                        _b)), onClick: onClear }, t('Clear')),
                React.createElement(Button, { variant: "flat", size: "small", className: classnames(datepickerTheme.footer__done, (_c = {},
                        _c[datepickerTheme.footer__done_notActive] = !isDoneable,
                        _c)), onClick: isDoneable ? onDone : null }, t('Done')))));
    }, [tab, props.isDoneable, props.isClearable, onDone, props.onClear]);
    var renderMobileFooter = useCallback(function (_a) {
        var _b, _c;
        var notActive = _a.notActive, isDoneable = _a.isDoneable, onClear = _a.onClear, onDone = _a.onDone;
        return (React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH - 1 }, outDate && (React.createElement("div", { className: datepickerTheme.footer__buttons },
            React.createElement("div", { className: classnames(datepickerTheme.footer__clear, (_b = {},
                    _b[datepickerTheme.footer__clear_notActive] = notActive,
                    _b)), onClick: onClear }, t('Clear')),
            React.createElement("div", { className: classnames(datepickerTheme.footer__done, (_c = {},
                    _c[datepickerTheme.footer__done_notActive] = isDoneable,
                    _c)), onClick: isDoneable ? onDone : null }, tab === TabTypes.PriceGraph && outDate && !returnDate ? t('One way') : t('Done'))))));
    }, [tab, props.isDoneable, props.isClearable, onDone, props.onClear]);
    var buttonRendererProps = {
        notActive: props.isClearable,
        onClear: props.onClear,
        onClose: onClose,
        isDoneable: props.isDoneable,
        onDone: onDone,
        tab: tab,
        routeType: props.routeType,
        isDateBackSelected: props.isDateBackSelected,
        isDateToSelected: props.isDateToSelected,
        isMultyCity: isMultyCity
    };
    var desktopMonthCount = isNarrowDesktop ? 2 : (_e = props.monthCount) !== null && _e !== void 0 ? _e : 3;
    return (React.createElement(ThemeProvider, { value: mergedTheme },
        React.createElement("div", { ref: ref, className: datepickerTheme.inputWrapper },
            React.createElement("input", { placeholder: props.inputPlaceholder, className: classnames(props.inputClassName, (_a = {}, _a[props.inputFocusedClassName] = isOpen, _a)), onClick: open, autoComplete: "off", ref: refEl, value: props.inputValue, readOnly: true }),
            props.valueRenderer && props.valueRenderer(isOpen, open, onClose)),
        React.createElement(DialogAdapter, { onClose: onClose, isOpen: isOpen, outsideClickIgnoreClass: props.outsideClickIgnoreClass, popupClassName: datepickerTheme.dialog },
            React.createElement("div", { className: classnames(datepickerTheme.popup, (_b = {},
                    _b[datepickerTheme.popup_withoutTabs] = !isTabDisplay,
                    _b)), "data-search-form-datepicker": true },
                props.showProgress && (React.createElement(LinearProgress, { variant: "indeterminate", classes: {
                        root: datepickerTheme.progress__root,
                        barColorPrimary: datepickerTheme.progress__bar__color,
                        colorPrimary: datepickerTheme.progress__color
                    } })),
                React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                    React.createElement("div", { className: datepickerTheme.dialog__bar },
                        React.createElement("div", { className: datepickerTheme.dialog__dates, onClick: onClose },
                            React.createElement("span", { className: classnames((_c = {},
                                    _c[datepickerTheme.dialog__activeDate] = props.routeType === 'outbound',
                                    _c)), onClick: function (e) {
                                    e.stopPropagation();
                                    props.onClickOutboundDate(e);
                                } },
                                React.createElement("span", null, outDate
                                    ? removeDotsFromDate(format(outDate, 'd MMM, EEEEEE'))
                                    : t('Outbound'))),
                            outDate && (React.createElement("span", { className: classnames(datepickerTheme.dialog__dateBack, (_d = {},
                                    _d[datepickerTheme.dialog__activeDate] = props.routeType === 'return',
                                    _d)), onClick: function (e) {
                                    e.stopPropagation();
                                    props.onClickReturnDate(e);
                                } },
                                React.createElement("span", null, returnDate
                                    ? removeDotsFromDate(format(returnDate, 'd MMM, EEEEEE'))
                                    : t('Return'))))),
                        React.createElement("div", { onClick: onClose, className: datepickerTheme.dialog__close }))),
                React.createElement("div", { className: classnames(datepickerTheme.content), ref: contentRef },
                    tab === TabTypes.PlainCalendar && (React.createElement(React.Fragment, null,
                        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
                            React.createElement(Datepicker, { onSelect: onSelect, onHover: props.onHover, hoverDates: props.hoverDates, openDate: props.openDate, highlightedDates: props.highlightedDates, selectedDates: props.selectedDates, monthCount: desktopMonthCount, minDate: props.minDate, maxDate: props.maxDate, dayRenderer: props.useThemeWithPrices && dayWithPrice })),
                        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                            React.createElement(Datepicker, { getParentRef: function () { return contentRef; }, onSelect: onSelect, onHover: props.onHover, hoverDates: props.hoverDates, openDate: props.openDate, highlightedDates: props.highlightedDates, selectedDates: props.selectedDates, monthCount: 12, minDate: props.minDate, maxDate: props.maxDate, dayRenderer: props.useThemeWithPrices && dayWithPrice })))),
                    tab === TabTypes.PriceGraph &&
                        (showOverlay ? (React.createElement(EmptyDatesOverlay, { onClick: handleOverlayButtonClick })) : (React.createElement(ThemeProvider, { value: {
                                PriceGraph: __assign(__assign({}, priceGraphTheme), { LegendsStyles: legendStyles })
                            } },
                            React.createElement(React.Suspense, { fallback: React.createElement("p", null, "...") }, props.showPriceGraph && pricesLoaded() ? (React.createElement(PriceGraph, { outDate: outDate, backDate: returnDate, openDate: props.selectedDates && props.selectedDates.length
                                    ? props.selectedDates[0]
                                    : props.openDate, onPricesEnd: props.onPriceGraphPricesEnd, onSelect: onSelect, prices: props.routeType === 'return' ? props.pricesBack : props.pricesTo, routeType: props.routeType === 'return'
                                    ? RouteType.Return
                                    : RouteType.Outbound })) : (React.createElement(DummyPriceGraph, null)))))),
                    tab === TabTypes.RoundtripMatrix && (React.createElement(PriceMatrixProvider, { value: priceMatrixTheme },
                        React.createElement(React.Suspense, { fallback: React.createElement("p", null, "...") },
                            React.createElement(PriceMatrix, { prices: props.matrixPrices, size: 7, onDateSelect: props.onRTSelect })))),
                    props.useThemeWithPrices && (React.createElement("div", { className: datepickerTheme.datepicker__disclaimer }, t('Approximate prices for 1 person for 1 direction')))),
                React.createElement("div", { className: datepickerTheme.footer },
                    isTabDisplay && (React.createElement(Tabs, { value: tab, onChange: function (ecent, newValue) { return setTab(newValue); }, classes: {
                            root: datepickerTheme.tabs,
                            indicator: datepickerTheme.tab__indicator
                        } },
                        React.createElement(Tab, { disableRipple: true, classes: {
                                root: datepickerTheme.tab,
                                wrapper: datepickerTheme.tab__wrapper,
                                selected: datepickerTheme.tab_selected
                            }, label: t('Calendar'), icon: React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH }, calendarIcon) }),
                        props.showPriceGraph && (React.createElement(Tab, { disableRipple: true, classes: {
                                root: datepickerTheme.tab,
                                wrapper: datepickerTheme.tab__wrapper,
                                selected: datepickerTheme.tab_selected
                            }, label: t('Price graph'), icon: React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH }, graphIcon) })),
                        props.showPriceMatrix && (React.createElement(Tab, { disableRipple: true, classes: {
                                root: classnames(datepickerTheme.tab, datepickerTheme.tab_matrix),
                                selected: datepickerTheme.tab_selected
                            }, label: t('Price matrix') })))),
                    renderAdaptiveSearchFormDatepickerControls
                        ? renderAdaptiveSearchFormDatepickerControls(buttonRendererProps)
                        : renderTabletFooter(buttonRendererProps)),
                renderAdaptiveSearchFormDatepickerControls
                    ? renderAdaptiveSearchFormDatepickerControls(buttonRendererProps)
                    : renderMobileFooter(buttonRendererProps)))));
}));
export default SearchFormDatepicker;
