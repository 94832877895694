import * as React from 'react';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import TransferSummary from '../../../ServiceSummary/services/TransferSummary';
import { getTravellersMap } from '../../../utils';
var TransferExtraCart = function (_a) {
    var travellers = _a.travellers, services = _a.services, onServiceDelete = _a.onServiceDelete, flight = _a.flight;
    return (React.createElement(ServiceSummaryDivider, null,
        React.createElement(TransferSummary, { services: services, onServiceDelete: onServiceDelete, travellers: travellers, travellersById: getTravellersMap(travellers), flight: flight }, function (children) { return React.createElement("span", null, children); })));
};
export default TransferExtraCart;
