import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CartService } from '../../Cart/types';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { Meal } from '../../Icons';
import { getActualProduct } from '../utils';
import CommonSummary from './CommonSummary';
var MealSummary = function (props) {
    var t = useTranslation('Cart').t;
    var onDeleteHandler = function (travellerId, serviceId, segmentId, allSegments) {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Meal, {
            type: OrderAdditionalServiceGdsServiceServiceType.Meal,
            passengerId: travellerId,
            segmentId: segmentId,
            serviceId: serviceId,
            allSegments: allSegments
        });
    };
    return (React.createElement(CommonSummary, { flight: props.flight, travellers: props.travellers, services: props.services, travellersById: props.travellersById, groupType: OrderAdditionalServiceGdsServiceServiceType.Meal, serviceFilterPredicate: function () { return true; }, serviceOptionGroupFactory: function (_a) {
            var items = _a.items, travellerId = _a.travellerId;
            return ({
                traveller: props.travellersById.get(travellerId),
                headerType: 'travellerName',
                items: items,
                headerIcon: Meal
            });
        }, serviceOptionItemFactory: function (_a) {
            var selectedService = _a.selectedService, key = _a.key, travellerId = _a.travellerId;
            var segment = props.flight.segments.find(function (segment) { return segment.segment.id === key; }), direction = key === 'allSegments'
                ? t('All flights')
                : "".concat(segment.segment.departure.airport.iata, " - ").concat(segment.segment.arrival.airport.iata);
            var actualProduct = getActualProduct(selectedService.products);
            return {
                name: "".concat(direction, ", ").concat(selectedService.count, "x ").concat(selectedService.service.name),
                id: selectedService.service.id,
                segmentKey: key,
                included: selectedService.count === selectedService.confirmedCount,
                price: selectedService.service.price,
                status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                onDelete: function () { return onDeleteHandler(travellerId, selectedService.serviceId, key, key === 'allSegments'); }
            };
        } }, function (children) { return props.children(children); }));
};
export default MealSummary;
