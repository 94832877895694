import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAeroexpress, getAvailableGdsServicesByType, getBaggageDelivery, getGroundTransportationServices, getInsurances, getOrder, getPriorityBoarding, getTravellers } from '../../Checkout/store/order/selectors';
import { removeServiceAction, setMultipleSelectedServices, setServiceAction } from '../../Checkout/store/selectedServices/actions';
import { getPassengerLabels, getPassengerState } from '../../Checkout/store/passengers/selectors';
import { getPassengerStateCompatible, getSelectedServicesState } from '../../Checkout/store/selectedServices/selectors';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { Money } from '../../index';
import { getPassengerPricesMap, getVipServicesToDelete } from '../utils';
import { getMinPrice, getPassengerTypeLabel, isUnconfirmedService } from '../../utils';
import { getAnimalTotalPrice } from './utils';
export var useServicePageProps = function () {
    var order = useSelector(getOrder);
    var passengers = useSelector(getPassengerStateCompatible);
    var passengerLabels = useSelector(getPassengerLabels);
    var _a = __read(useState(null), 2), openedModal = _a[0], setOpenedModal = _a[1];
    var toggleModal = function (type) {
        if (!!type) {
            setOpenedModal(type);
        }
        else {
            setOpenedModal(null);
        }
    };
    return {
        order: order,
        passengers: passengers,
        passengerLabels: passengerLabels,
        openedModal: openedModal,
        toggleModal: toggleModal
    };
};
export var useCheckoutServicesCardsAvailability = function () {
    var _a, _b;
    var aeroexpress = useSelector(getAeroexpress);
    var insurance = useSelector(getInsurances);
    var priorityBoarding = useSelector(getPriorityBoarding);
    var gdsServices = useSelector(getAvailableGdsServicesByType);
    var baggageDelivery = useSelector(getBaggageDelivery);
    var transferServices = useSelector(getGroundTransportationServices);
    return __assign({ Insurance: ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) > 0, Aeroexpress: ((_b = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips) === null || _b === void 0 ? void 0 : _b.length) > 0, Transfer: (transferServices === null || transferServices === void 0 ? void 0 : transferServices.length) > 0, PriorityBoarding: (priorityBoarding === null || priorityBoarding === void 0 ? void 0 : priorityBoarding.length) > 0, BaggageDelivery: (baggageDelivery === null || baggageDelivery === void 0 ? void 0 : baggageDelivery.length) > 0 }, gdsServices);
};
export var useOrderService = function (orderServices, type) {
    var t = useTranslation('Checkout').t;
    var dispatch = useDispatch();
    var travellers = useSelector(getTravellers);
    var passengers = useSelector(getPassengerState);
    var selectedServices = useSelector(getSelectedServicesState);
    var _a = __read(React.useState(false), 2), opened = _a[0], setOpened = _a[1];
    var onOpen = function () { return setOpened(true); };
    var onClose = function () { return setOpened(false); };
    var isSelected = React.useMemo(function () {
        return passengers.some(function (passenger) {
            return orderServices.some(function (service) { return selectedServices[passenger.id].hasOwnProperty(service.id); });
        });
    }, [passengers, selectedServices]);
    var unconfirmedServices = React.useMemo(function () {
        var _a, _b, _c, _d;
        var services = [];
        if (Object.keys(selectedServices !== null && selectedServices !== void 0 ? selectedServices : {}).length) {
            var _loop_1 = function (passengerId) {
                var _loop_2 = function (serviceId) {
                    var traveller = travellers.find(function (traveller) { return traveller.id === passengerId; });
                    if (!traveller) {
                        return "continue";
                    }
                    var service = (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(function (service) { return service.serviceId === serviceId; });
                    if (!service) {
                        return "continue";
                    }
                    var isUnconfirmed = (_d = service.products) === null || _d === void 0 ? void 0 : _d.some(function (product) { return isUnconfirmedService(product.status); });
                    if (isUnconfirmed) {
                        services = __spreadArray(__spreadArray([], __read(services), false), [
                            {
                                passengerId: passengerId,
                                serviceId: service.serviceId,
                                segmentIds: service.segmentIds,
                                count: service.count
                            }
                        ], false);
                    }
                };
                for (var serviceId in selectedServices[passengerId]) {
                    _loop_2(serviceId);
                }
            };
            for (var passengerId in selectedServices) {
                _loop_1(passengerId);
            }
        }
        return services;
    }, [travellers, selectedServices]);
    var onSaveHandler = function (newSelectedServices) {
        batch(function () {
            dispatch(setMultipleSelectedServices(newSelectedServices.map(function (selectedService) { return ({
                service: {
                    id: selectedService.serviceId,
                    type: type
                },
                serviceId: selectedService.serviceId,
                segmentId: selectedService.segmentIds[0],
                passengerId: selectedService.passengerId,
                count: selectedService.count
            }); })));
        });
        onClose();
    };
    var onRemoveHandler = function (services) {
        batch(function () {
            services.forEach(function (service) {
                dispatch(setServiceAction({
                    service: {
                        id: service.serviceId,
                        type: type
                    },
                    serviceId: service.serviceId,
                    segmentId: service.segmentIds[0],
                    passengerId: service.passengerId,
                    count: 0
                }));
            });
        });
        onClose();
    };
    var onRemoveCartService = React.useCallback(function (_, payload) {
        var servicePayload = payload;
        var servicesToDelete = getVipServicesToDelete(servicePayload, travellers);
        onRemoveHandler(servicesToDelete);
    }, [travellers]);
    var availablePassengers = React.useMemo(function () { return passengers.filter(function (passenger) { return passenger.type !== AviaPassengerType.INF; }); }, [passengers]);
    var isDifferentPrices = React.useMemo(function () { return new Set(orderServices.map(function (service) { return service.price.amount; })).size > 1; }, [passengers, orderServices]);
    var passengerPrices = React.useMemo(function () { return getPassengerPricesMap(passengers, orderServices, isDifferentPrices); }, [
        passengers,
        orderServices,
        isDifferentPrices
    ]);
    var renderSubheader = React.useCallback(function () {
        return passengerPrices.map(function (passenger, index) { return (React.createElement("span", { key: index },
            getPassengerTypeLabel(passenger.type, passenger.supplierType),
            ": ",
            isDifferentPrices && t('from'),
            React.createElement(Money, { money: passenger.price }))); });
    }, [passengerPrices, isDifferentPrices]);
    var minPrice = React.useMemo(function () {
        return getMinPrice(passengerPrices, function (item) { return item.price; });
    }, [isSelected, passengerPrices]);
    return {
        isSelected: isSelected,
        selectedServices: selectedServices,
        unconfirmedServices: unconfirmedServices,
        renderSubheader: renderSubheader,
        onOpen: onOpen,
        onClose: onClose,
        setOpened: setOpened,
        onSaveHandler: onSaveHandler,
        onRemoveHandler: onRemoveHandler,
        onRemoveCartService: onRemoveCartService,
        passengers: availablePassengers,
        opened: opened,
        getMinPrice: minPrice
    };
};
export var useAnimalService = function (services, travellers) {
    var dispatch = useDispatch();
    var servicesByRfisc = useMemo(function () {
        var servicesMap = new Map();
        services.forEach(function (service) {
            var _a;
            var allowedSegmentsKey = service.allowedSegments
                .reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), [ids.join('')], false); }, [])
                .join('-');
            var allowedPassengersKey = service.allowedPassengers
                .reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), [ids.join('')], false); }, [])
                .join('-');
            // key - rfisc/segmentIds/passengerIds
            var key = [service.rfisc, allowedSegmentsKey, allowedPassengersKey].join('/');
            var baggageService = service;
            servicesMap.set(key, __spreadArray(__spreadArray([], __read(((_a = servicesMap.get(key)) !== null && _a !== void 0 ? _a : [])), false), [baggageService], false));
        });
        return __spreadArray([], __read(servicesMap.values()), false).map(function (services) {
            var actualService = null;
            services.forEach(function (service) {
                if (!actualService || (actualService === null || actualService === void 0 ? void 0 : actualService.id) < service.id) {
                    actualService = service;
                }
            });
            return actualService;
        });
    }, [services]);
    var onSave = useCallback(function (selectedServices) {
        var selectedServicesToAdd = selectedServices.filter(function (service) { return service.count > 0; });
        var selectedServicesToRemove = selectedServices.filter(function (service) { return service.count === 0; });
        if (selectedServicesToAdd.length) {
            dispatch(setMultipleSelectedServices(selectedServicesToAdd.map(function (service) { return ({
                segmentId: service.segmentId,
                allowedSegments: [service.segmentIds],
                passengerId: service.passengerId,
                serviceId: service.serviceId,
                count: service.count,
                service: {
                    id: service.serviceId,
                    segmentId: service.segmentId,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                }
            }); })));
        }
        if (selectedServicesToRemove.length) {
            selectedServicesToRemove.forEach(function (service) {
                dispatch(removeServiceAction({
                    segmentId: service.segmentId,
                    segmentIds: service.segmentIds,
                    passengerId: service.passengerId,
                    serviceId: service.serviceId,
                    count: 0,
                    service: {
                        id: service.serviceId,
                        segmentId: service.segmentId,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    }
                }));
            });
        }
    }, [travellers, dispatch]);
    var onClear = useCallback(function (selectedServices) {
        selectedServices.forEach(function (service) {
            dispatch(removeServiceAction({
                segmentId: service.segmentId,
                segmentIds: service.segmentIds,
                passengerId: service.passengerId,
                serviceId: service.serviceId,
                count: 0,
                service: {
                    id: service.serviceId,
                    segmentId: service.segmentId,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                }
            }));
        });
    }, [dispatch]);
    var minPrice = useMemo(function () {
        if (servicesByRfisc.length) {
            return getMinPrice(servicesByRfisc, function (service) {
                var maxSegmentCount = Math.max.apply(Math, __spreadArray([], __read(service.allowedSegments.map(function (ids) { return ids.length; })), false));
                return __assign(__assign({}, service.price), { amount: service.price.amount * maxSegmentCount });
            });
        }
        return null;
    }, [servicesByRfisc]);
    var totalPrice = useMemo(function () { return getAnimalTotalPrice(services, travellers); }, [services, travellers]);
    return {
        servicesByRfisc: servicesByRfisc,
        minPrice: minPrice,
        totalPrice: totalPrice,
        onSave: onSave,
        onClear: onClear
    };
};
