import { produce } from 'immer';
import { initialStateFactory } from './initialStateFactory';
export var useMethods = function (state, setState, service, parentState) {
    var onToggle = function (passengerId, segmentId) {
        setState(produce(state, function (draft) {
            draft[passengerId][segmentId].selected = !draft[passengerId][segmentId].selected;
            return draft;
        }));
    };
    var restoreState = function () { return setState(initialStateFactory(service, parentState)); };
    return { onToggle: onToggle, restoreState: restoreState };
};
