import { __read, __values } from "tslib";
import { useMemo } from 'react';
import { GdsServiceVipSubType } from '@websky/graphql';
import { getPassengerLabel, isCheckoutOrderGuard } from '../../../../utils';
export var useVariables = function (order, state, activeSegment) {
    var transferServices = useMemo(function () {
        var _a, _b, _c, _d;
        return ((_d = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(function (service) {
            return service.subType ===
                GdsServiceVipSubType.GroundTransportation;
        })) !== null && _d !== void 0 ? _d : []);
    }, [order.additionalServices]);
    var notNullPriceServices = useMemo(function () {
        return transferServices.filter(function (transferService) { var _a; return ((_a = transferService === null || transferService === void 0 ? void 0 : transferService.price) === null || _a === void 0 ? void 0 : _a.amount) !== 0; });
    }, [transferServices]);
    var segmentsById = useMemo(function () {
        var segmentsById = {};
        if (isCheckoutOrderGuard(order)) {
            order.flight.segments.forEach(function (segment) {
                segmentsById[segment.segment.id] = {
                    city: segment.segment.departure.airport.city.name,
                    date: segment.segment.departure.date
                };
            });
        }
        return segmentsById;
    }, [order]);
    var passengersById = useMemo(function () {
        var passengersById = {};
        var number = 1;
        if (isCheckoutOrderGuard(order)) {
            order.travellers.forEach(function (traveller) {
                passengersById[traveller.id] = {
                    number: number.toString(10),
                    fullName: getPassengerLabel(traveller)
                };
                number++;
            });
        }
        return passengersById;
    }, [order]);
    var selectedServicesIds = useMemo(function () {
        var e_1, _a, e_2, _b;
        var selectedServicesIds = new Set();
        try {
            for (var _c = __values(Object.keys(state.services)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 1), passengerId = _e[0];
                try {
                    for (var _f = (e_2 = void 0, __values(Object.keys(state.services[passengerId]))), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var _h = __read(_g.value, 1), segmentId = _h[0];
                        state.services[passengerId][segmentId].forEach(function (service) {
                            if (service === null || service === void 0 ? void 0 : service.selected) {
                                selectedServicesIds.add(service.service.id);
                            }
                        });
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return selectedServicesIds;
    }, [state.services]);
    var activeSegmentServices = useMemo(function () {
        if (!activeSegment) {
            return notNullPriceServices;
        }
        return notNullPriceServices.filter(function (service) {
            var allowedSegments = Array.prototype.concat.apply([], service.allowedSegments);
            return allowedSegments.includes(activeSegment);
        });
    }, [notNullPriceServices, activeSegment]);
    var initialState = useMemo(function () {
        return JSON.stringify(state);
    }, []);
    var currentState = useMemo(function () {
        return JSON.stringify(state);
    }, [state]);
    var stateChanged = useMemo(function () {
        return initialState !== currentState;
    }, [initialState, currentState]);
    return {
        transferServices: transferServices,
        segmentsById: segmentsById,
        passengersById: passengersById,
        selectedServicesIds: selectedServicesIds,
        activeSegmentServices: activeSegmentServices,
        stateChanged: stateChanged
    };
};
