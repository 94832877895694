import { __assign } from "tslib";
import React from 'react';
import Button from '../../Button';
import { useTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import ModalX from '../../BaseComponents/ModalX';
import classNames from 'classnames';
initI18n('ModalConfirm');
var ModalConfirm = function (_a) {
    var classes = _a.classes, title = _a.title, cancelBtn = _a.cancelBtn, confirmBtn = _a.confirmBtn, customButtons = _a.customButtons, _b = _a.hideFooter, hideFooter = _b === void 0 ? false : _b, _c = _a.hideTitle, hideTitle = _c === void 0 ? false : _c, children = _a.children, fullScreen = _a.fullScreen, scroll = _a.scroll, isOpen = _a.isOpen, onClose = _a.onClose, onCancel = _a.onCancel, onConfirm = _a.onConfirm, ignoreClose = _a.ignoreClose;
    var theme = useTheme('ModalConfirm').ModalConfirm;
    var t = useTranslation('ModalConfirm').t;
    function ConfirmButton() {
        return (React.createElement(Button, { variant: "flat", classes: {
                root: classNames(theme.confirmButton, classes === null || classes === void 0 ? void 0 : classes.confirmButton),
                disabled: theme.disabled
            }, onClick: onConfirm }, !confirmBtn ? t('Confirm') : confirmBtn));
    }
    function CancelButton() {
        return (React.createElement(Button, { variant: "flat", classes: {
                root: classNames(theme.cancelButton, classes === null || classes === void 0 ? void 0 : classes.cancelButton),
                disabled: theme.disabled
            }, onClick: onCancel }, !cancelBtn ? t('Cancel') : cancelBtn));
    }
    function Buttons() {
        if (customButtons) {
            return React.createElement("div", { className: classNames(theme.buttons, classes === null || classes === void 0 ? void 0 : classes.buttons) }, customButtons);
        }
        return (React.createElement("div", { className: classNames(theme.buttons, classes === null || classes === void 0 ? void 0 : classes.buttons) },
            React.createElement(CancelButton, null),
            React.createElement(ConfirmButton, null)));
    }
    function Title() {
        return (React.createElement("div", { className: classNames(theme.header, classes === null || classes === void 0 ? void 0 : classes.header) },
            React.createElement("div", { className: classNames(theme.title, classes === null || classes === void 0 ? void 0 : classes.title) }, title)));
    }
    function Footer() {
        return (React.createElement("div", { className: classNames(theme.footer, classes === null || classes === void 0 ? void 0 : classes.footer) },
            React.createElement(Buttons, null)));
    }
    return (React.createElement(ModalX, { isOpen: isOpen, onClose: onClose, ignoreClose: ignoreClose, fullScreen: fullScreen, scroll: scroll, classes: __assign(__assign({}, theme), { paper: classNames(theme.paper, classes === null || classes === void 0 ? void 0 : classes.paper), closeButton: classNames(theme.closeButton, classes === null || classes === void 0 ? void 0 : classes.closeButton) }), title: title && !hideTitle && React.createElement(Title, null), footer: !hideFooter && React.createElement(Footer, null) }, children && React.createElement("div", { className: theme.content }, children)));
};
export default ModalConfirm;
