import { __assign } from "tslib";
import * as React from 'react';
import ServiceSummary from '../../ServiceSummary';
import { useTranslation } from 'react-i18next';
import { SeatSelection } from '../../Icons';
import Aeroexpress from './Extra/Aeroexpress';
import Insurance from './Extra/Insurance';
import { useContext, useMemo } from 'react';
import { CartContext } from '../Cart';
import BusinessLounge from './Extra/BusinessLounge';
import Animal from './Extra/Animal';
import { getTotalPrice } from '../../utils';
import { CheckoutSteps } from '../../MobileStepbar/types';
import TransferExtraCart from './Extra/Transfer';
var Extra = function (props) {
    var t = useTranslation('Cart').t;
    var totalPrices = useContext(CartContext).totalPrices;
    var prices = useMemo(function () {
        if (props.separateInsurance) {
            return [totalPrices.aeroexpress, totalPrices.businessLounge, totalPrices.animals];
        }
        return [
            totalPrices.aeroexpress,
            totalPrices.businessLounge,
            totalPrices.insurance,
            totalPrices.animals,
            totalPrices.transfer
        ];
    }, [totalPrices, props.separateInsurance]);
    var extraTotalPrice = useMemo(function () { return getTotalPrice(prices, function (price) { return price; }); }, [prices]);
    var hasExtraServices = useMemo(function () { return prices.some(function (price) { return price === null || price === void 0 ? void 0 : price.amount; }); }, [prices]);
    var group = {
        header: t('Extra'),
        icon: SeatSelection,
        totalPrice: extraTotalPrice
    };
    var content = hasExtraServices ? (React.createElement(React.Fragment, null,
        totalPrices.aeroexpress !== null && React.createElement(Aeroexpress, __assign({}, props)),
        totalPrices.transfer !== null && React.createElement(TransferExtraCart, __assign({}, props)),
        totalPrices.insurance !== null && !props.separateInsurance && React.createElement(Insurance, __assign({}, props)),
        totalPrices.businessLounge !== null && React.createElement(BusinessLounge, __assign({}, props)),
        totalPrices.animals !== null && React.createElement(Animal, __assign({}, props)))) : null;
    if (props.renderService) {
        return props.renderService(CheckoutSteps.Extras, group, content);
    }
    if (!content) {
        return null;
    }
    return React.createElement(ServiceSummary, { group: group }, content);
};
export default Extra;
