import * as CheckoutAdditionalService from './CheckoutAdditionalService/CheckoutAdditionalService.css';
import * as CheckoutAdditionalServiceBaggage from './components/Baggage/CheckoutAdditionalServiceBaggage.css';
import * as CheckoutAdditionalServiceMeal from './components/Meal/CheckoutAdditionalServiceMeal.css';
import * as Seats from './components/Seats/Seats.css';
import * as Insurance from './components/Insurance/Insurance.css';
import * as Aeroexpress from './components/Aeroexpress/Aeroexpress.css';
import * as Loyalty from './components/Loyalty/Loyalty.css';
import * as Business from './components/Business/Business.css';
import * as PriorityBoarding from './components/PriorityBoarding/PriorityBoarding.css';
import * as CapsuleHotel from './components/CapsuleHotel/CapsuleHotel.css';
import * as Animal from './components/Animal/Animal.css';
import * as BaggageDelivery from './components/BaggageDelivery/BaggageDelivery.css';
import * as ServiceError from './components/ServiceError/ServiceError.css';
import * as AeroexpressPopup from './components/AeroexpressPopup/AeroexpressPopup.css';
import * as AeroexpressSegment from './components/AeroexpressSegment/AeroexpressSegment.css';
import * as AeroexpressPassenger from './components/AeroexpressPassenger/AeroexpressPassenger.css';
import * as PetPopup from './components/PetPopup/PetPopup.css';
import * as Control from './components/Control/Control.css';
import * as Close from './components/Close/Close.css';
import * as InsuranceUpsale from './components/InsuranceUpsale/InsuranceUpsale.css';
import * as Transfer from './components/Transfer/Transfer.css';
export default {
    CheckoutAdditionalService: CheckoutAdditionalService,
    CheckoutAdditionalServiceBaggage: CheckoutAdditionalServiceBaggage,
    CheckoutAdditionalServiceMeal: CheckoutAdditionalServiceMeal,
    Insurance: Insurance,
    Seats: Seats,
    Aeroexpress: Aeroexpress,
    Loyalty: Loyalty,
    Business: Business,
    PriorityBoarding: PriorityBoarding,
    CapsuleHotel: CapsuleHotel,
    Animal: Animal,
    BaggageDelivery: BaggageDelivery,
    ServiceError: ServiceError,
    AeroexpressPopup: AeroexpressPopup,
    AeroexpressSegment: AeroexpressSegment,
    AeroexpressPassenger: AeroexpressPassenger,
    PetPopup: PetPopup,
    Control: Control,
    Close: Close,
    InsuranceUpsale: InsuranceUpsale,
    Transfer: Transfer
};
