import { __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ThanksBanner from '../ThanksBanner/ThanksBanner';
import { MOBILE_MIN_WIDTH, scrollTo } from '../../../../utils';
import { StepType } from '../../../types';
import { useTheme } from '../../../../theme';
import { fillOrder as fillOrderAction } from '../../../store/order/actions';
import { getAnimalServices, getIsBooked, getIsBookedOrConfirmed, getIsCancelled, getIsConfirmed, getIsConfirmedOrCancelled, getIsFareLockConfirmed, getIsFareLockSelected, getIsReadonly, getIsTravellersDataFilled, getOrder } from '../../../store/order/selectors';
import WarningModal from '../../../../WarningModal';
import { Aeroexpress, Animal, Baggage, Loyalty, Meal, Seats, Transfer } from '../../../../CheckoutAdditionalService/components';
import { useCheckoutServicesCardsAvailability } from '../../../../CheckoutAdditionalService/components/hooks';
import SimpleLoader from '../../../../SimpleLoader';
import Business from '../../../../CheckoutAdditionalService/components/Business/Business';
import OrderCard from '../../../../OrderCard';
import { FlightSegmentStatus, InfoMessagePositionsEnum } from '@websky/graphql';
import { OrderIsArchivedTicketDownloadError, useDownloadOrderDocument } from '../hooks';
import { useFareLock } from '../../../../FareLock/hooks';
import FareLock from '../../../../FareLock';
import SelectedFlight from '../SelectedFlight/SelectedFlight';
import { WarningRounded } from '../../../../Icons';
import PriorityBoarding from '../../../../CheckoutAdditionalService/components/PriorityBoarding/PriorityBoarding';
import CapsuleHotel from '../../../../CheckoutAdditionalService/components/CapsuleHotel/CapsuleHotel';
import Notification from '../../../../Notification';
import { useConfig } from '../../../../context';
import PassengerToolbar from '../Form/PassengersToolbar/PassengersToolbar';
import { OverrideComponent } from '../../../../renderProps';
import BaggageDelivery from '../../../../CheckoutAdditionalService/components/BaggageDelivery/BaggageDelivery';
import Consents from '../../../../Consents';
import OrderExareStatus from '../OrderExareStatus/OrderExareStatus';
import getOrderDataLayer from '../../../../DataLayerAnalytics/orderDataLayerInstance';
import WebskyServicesAnalytics from '../../../../DataLayerAnalytics/WebskyServicesAnalytics';
import { useIsCheckinAvailable } from '../../../hooks';
import OrderInsuranceBlockSlot from '../OrderInsuranceBlockSlot/OrderInsuranceBlockSlot';
var Order = function (_a) {
    var _b;
    var _c, _d, _e;
    var setBookingError = _a.setBookingError, bookingError = _a.bookingError, _f = _a.showServiceCards, showServiceCards = _f === void 0 ? true : _f, goToExchange = _a.goToExchange, goToRefund = _a.goToRefund, goToChangePassenger = _a.goToChangePassenger, userInfo = _a.userInfo, refetchUser = _a.refetchUser, currentStep = _a.currentStep, paymentSuccess = _a.paymentSuccess, setStep = _a.setStep, setNextStep = _a.setNextStep;
    var css = useTheme('Checkout').CheckoutStyles;
    var t = useTranslation('Checkout').t;
    var servicesAvailability = useCheckoutServicesCardsAvailability();
    var order = useSelector(getOrder);
    var isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    var noAvailableServices = Object.keys(servicesAvailability).every(function (key) { return servicesAvailability[key] === false; });
    var isFareLockSelected = useSelector(getIsFareLockSelected);
    var isFareLockConfirmed = useSelector(getIsFareLockConfirmed);
    var animalServices = useSelector(getAnimalServices);
    var isCancelled = useSelector(getIsCancelled);
    var isReadonly = useSelector(getIsReadonly);
    var isBooked = useSelector(getIsBooked);
    var isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    var isConfirmed = useSelector(getIsConfirmed);
    var isConfirmedOrCancelled = useSelector(getIsConfirmedOrCancelled);
    var dispatch = useDispatch();
    var fillOrder = function (orderData) { return dispatch(fillOrderAction(orderData)); };
    var hasAnimal = ((_d = (_c = useConfig()) === null || _c === void 0 ? void 0 : _c.global.servicesAvailable) === null || _d === void 0 ? void 0 : _d.animal) === true;
    var isCheckinAvailable = useIsCheckinAvailable(order);
    var clearError = function () { return setBookingError(null); };
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _g = __read(React.useState(false), 2), showThanksBanner = _g[0], setShowThanksBanner = _g[1];
    useEffect(function () {
        if (paymentSuccess) {
            setShowThanksBanner(true);
        }
    }, [paymentSuccess]);
    useEffect(function () {
        // if there is no available services in mobile, redirect to payment
        if (Object.keys(servicesAvailability).every(function (key) { return servicesAvailability[key] === false; }) &&
            isBooked &&
            isMobile) {
            setStep(StepType.Payment);
        }
    }, [isBooked]);
    useEffect(function () {
        if (isBooked) {
            getOrderDataLayer().beginCheckout(WebskyServicesAnalytics.getCheckoutSelectedServices(order));
        }
    }, [isBooked, order.isPriceFreezeProductSelected]);
    var _h = useFareLock(order, fillOrder), handleChangeFareLock = _h.handleChangeFareLock, isSelected = _h.isSelected, price = _h.price, fareLockService = _h.fareLockService;
    var canceledFlights = React.useMemo(function () {
        return order.flight.segmentGroups.filter(function (group) {
            return group.segments.some(function (segment) { return segment.status === FlightSegmentStatus.Canceled; });
        });
    }, [order.flight]);
    var _j = useDownloadOrderDocument(), orderDocDownloading = _j.loading, handleDownload = _j.handleDownload, docError = _j.error, docWarningIsOpen = _j.warningIsOpen, docWarningClose = _j.warningClose;
    useEffect(function () {
        scrollTo(0);
    }, []);
    return (React.createElement("div", { className: cn(css.checkout, (_b = {},
            _b[css.checkout_margined] = isCancelled,
            _b)) },
        orderDocDownloading && React.createElement(SimpleLoader, null),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An unexpected error has occurred during the booking process. Please try again later or contact a customer support service.'), errorMessage: bookingError ? bookingError.message.replace('GraphQL error: ', '') : '', onClose: clearError, onButtonClick: clearError, buttonText: t('OK, thank you'), isOpen: !!bookingError }),
        React.createElement(WarningModal, { title: t('Oops...'), content: docError instanceof OrderIsArchivedTicketDownloadError ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: t(docError.message) } })) : (t('An unexpected error has occurred during the tickets downloading process. Please try again later or contact a customer support service.')), errorMessage: docError && !(docError instanceof OrderIsArchivedTicketDownloadError)
                ? docError.message.replace('GraphQL error: ', '')
                : '', onClose: docWarningClose, onButtonClick: docWarningClose, buttonText: t('OK, thank you'), isOpen: !!docWarningIsOpen }),
        isConfirmed && showThanksBanner && React.createElement(ThanksBanner, null),
        isConfirmed && canceledFlights.length > 0 && (React.createElement("div", { className: css.notice },
            WarningRounded,
            ' ',
            t('Attention, times have changed for flight {{route}}', {
                route: "".concat(canceledFlights[0].segments[0].departure.airport.city.name, " - ").concat(canceledFlights[0].segments[canceledFlights[0].segments.length - 1].arrival.airport.city
                    .name)
            }))),
        React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutOrderAdditionalBanner: function () { return null; } } }),
        React.createElement(OverrideComponent, { componentProps: { locator: order.locator }, component: { CheckoutOrderExtraHeader: function () { return null; } } }),
        !isReadonly && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: css.passengers__header },
                React.createElement("h1", { className: css.passengers__header__title }, t('Passengers'))))),
        React.createElement(OrderExareStatus, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger }),
        isConfirmedOrCancelled && (React.createElement("div", { className: css.paidOrderWrapper },
            React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, onDownloadClick: handleDownload, order: order, isCheckinOpen: isCheckinAvailable, type: 'review' }))),
        isBooked && (React.createElement("div", { className: css.summary__wrp },
            React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement("h3", { className: css.summary__header }, t('Review your trip')))),
            !!order.messages.length && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css.messages },
                    order.messages
                        .filter(function (message) { var _a, _b; return !((_b = (_a = message.positions) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, InfoMessagePositionsEnum.flightPrice)); })
                        .map(function (message, index) { return (React.createElement(Notification, { key: index, className: css.messages_item, title: message.title, image: message.image, status: message.type, infoMessage: message, type: 'context' })); }),
                    React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutPassengersNotifications: function () { return null; } } })),
                React.createElement("br", null))),
            !isConfirmed && isFareLockSelected && (React.createElement(FareLock, { freezeUntil: fareLockService.freezeUntil, price: price, onChange: handleChangeFareLock, isActive: isSelected, confirmed: isSelected || isFareLockConfirmed, addedToOrder: true })),
            React.createElement("div", { className: css.summary },
                React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, order: order, isCheckinOpen: isCheckinAvailable, type: 'review' })))),
        (isBookedOrConfirmed || isConfirmedOrCancelled) && (React.createElement(OverrideComponent, { component: { CheckoutSelectedFlight: SelectedFlight }, componentProps: {
                flight: order.flight,
                confirmed: isConfirmedOrCancelled,
                orderId: order.id
            } })),
        showServiceCards && isBookedOrConfirmed && !noAvailableServices && isTravellersDataFilled && (React.createElement(React.Fragment, null, Object.values(servicesAvailability).includes(true) && (React.createElement("div", { className: css.checkoutServices },
            servicesAvailability.Seat && React.createElement(Seats, { isCheckinAvailable: isCheckinAvailable }),
            servicesAvailability.Baggage && React.createElement(Baggage, null),
            servicesAvailability.Meal && React.createElement(Meal, null),
            servicesAvailability.Insurance && (React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutInsurance: OrderInsuranceBlockSlot } })),
            servicesAvailability.Aeroexpress && React.createElement(Aeroexpress, null),
            servicesAvailability.Transfer && React.createElement(Transfer, null),
            ((_e = order === null || order === void 0 ? void 0 : order.ffpInfo) === null || _e === void 0 ? void 0 : _e.ffpSupported) && React.createElement(Loyalty, { userInfo: userInfo, refetchUser: refetchUser }),
            servicesAvailability.BusinessLounge && (React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutBusiness: Business } })),
            servicesAvailability.PriorityBoarding && React.createElement(PriorityBoarding, null),
            servicesAvailability.BusinessLounge && React.createElement(CapsuleHotel, null),
            hasAnimal && animalServices.length > 0 && React.createElement(Animal, null),
            servicesAvailability.BaggageDelivery && React.createElement(BaggageDelivery, null))))),
        React.createElement(Consents, null, function (_a) {
            var onConfirm = _a.onConfirm;
            return (React.createElement(PassengerToolbar, { currentStep: currentStep, setNextStep: setNextStep, onConfirmConsents: onConfirm }));
        })));
};
export default Order;
