import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { SlideBottom } from '../index';
import { initI18n, useIsMobile } from '../utils';
import AddPassengerForm from './components/AddPassengerForm/AddPassengerForm';
import { useToggleable } from '../hooks';
import { Add } from '../Icons';
initI18n('AddPassenger');
var AddPassenger = function (_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation('AddPassenger').t;
    var css = useTheme('AddPassenger').AddPassenger;
    var _b = useToggleable(false), isOpen = _b.isOpen, open = _b.open, close = _b.close;
    var isMobile = useIsMobile();
    return (React.createElement("div", { className: css.wrapper },
        (!isOpen || isMobile) && (React.createElement("div", { className: css.addButton, onClick: open },
            React.createElement("div", { className: css.addButton__icon }, Add),
            React.createElement("span", null, t('Add passenger')))),
        isOpen && !isMobile && (React.createElement("div", { className: css.form__wrapper },
            React.createElement(AddPassengerForm, { onSubmit: onSubmit, onSuccess: close }))),
        React.createElement(SlideBottom, { isOpen: isOpen && isMobile, onClose: close },
            React.createElement("div", { className: css.form__wrapper },
                React.createElement("div", { className: css.form__title }, t('Add passenger')),
                React.createElement(AddPassengerForm, { onSubmit: onSubmit, onSuccess: close, variant: "standard" })))));
};
export default AddPassenger;
