import { __assign, __awaiter, __generator, __read, __rest } from "tslib";
import { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from './context';
import { Log } from './sentry';
export var useToggleable = function (initialState) {
    var _a = __read(useState(initialState), 2), isOpen = _a[0], setOpen = _a[1];
    var open = useCallback(function () {
        setOpen(true);
    }, [isOpen]);
    var close = useCallback(function () {
        setOpen(false);
    }, [isOpen]);
    var toggle = useCallback(function () {
        setOpen(!isOpen);
    }, [isOpen]);
    return {
        isOpen: isOpen,
        open: open,
        close: close,
        toggle: toggle,
        setOpen: setOpen
    };
};
export function useSelectable(initial) {
    var _a = __read(useState(initial), 2), active = _a[0], setActive = _a[1];
    var onSelect = useCallback(function (newActive) {
        setActive(newActive);
        return newActive;
    }, [active]);
    return {
        active: active,
        onSelect: onSelect
    };
}
export var useIataCode = function () {
    var _a, _b, _c;
    try {
        var iataCode = useConfig().global.companyInfo.iataCode;
        return iataCode;
    }
    catch (_d) {
        return (_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode;
    }
};
export var useConfigRedirects = function () {
    var accountURL = useConfig().Engine.accountURL;
    var onGoToAccountSettings = function () {
        window.open("".concat(accountURL, "/settings"));
    };
    return { onGoToAccountSettings: onGoToAccountSettings };
};
export var useHasLoyaltyProgram = function () {
    var _a, _b, _c;
    try {
        var loyaltyProgramRegistrationUrl = useConfig().global.companyInfo.loyaltyProgramRegistrationUrl;
        return !!loyaltyProgramRegistrationUrl;
    }
    catch (_d) {
        return !!((_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.loyaltyProgramRegistrationUrl);
    }
};
export var useSentryTranslation = function (ns, options) {
    var _a = useTranslation(ns, options), i18nT = _a.t, i18n = _a.i18n, translation = __rest(_a, ["t", "i18n"]);
    var t = function (key) {
        if (!i18n.exists(key, { ns: ns })) {
            Log({ level: 'warning', message: "The translation key '".concat(ns, ":").concat(key, "' is missing") });
            return null;
        }
        return i18nT(key);
    };
    return __assign(__assign({}, translation), { t: t, i18n: i18n });
};
export var useConditionalPolling = function (fetchFn, condition, options) {
    var _a = options.interval, interval = _a === void 0 ? 3000 : _a, _b = options.maxQueryCount, maxQueryCount = _b === void 0 ? 3 : _b, progressiveIntervalPerQuery = options.progressiveIntervalPerQuery;
    var timeoutId = useRef();
    var queryCount = useRef(1);
    var onFetchHandler = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchFn()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [fetchFn]);
    useEffect(function () {
        var polling = function () { return __awaiter(void 0, void 0, void 0, function () {
            var timeout;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, onFetchHandler()];
                    case 1:
                        _a.sent();
                        if (!condition || queryCount.current >= maxQueryCount) {
                            clearTimeout(timeoutId.current);
                            timeoutId.current = undefined;
                            queryCount.current = 1;
                            return [2 /*return*/];
                        }
                        timeout = progressiveIntervalPerQuery ? interval * queryCount.current : interval;
                        timeoutId.current = setTimeout(polling, timeout);
                        queryCount.current++;
                        return [2 /*return*/];
                }
            });
        }); };
        if (condition) {
            polling();
        }
        return function () {
            setTimeout(function () {
                if (timeoutId.current) {
                    clearTimeout(timeoutId.current);
                    timeoutId.current = undefined;
                    queryCount.current = 1;
                }
            });
        };
    }, [onFetchHandler, condition, interval, queryCount, maxQueryCount, progressiveIntervalPerQuery]);
};
