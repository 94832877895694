import { __assign, __read, __values } from "tslib";
import { useMemo } from 'react';
export var useVariables = function (state, service) {
    var totalPrice = useMemo(function () {
        var e_1, _a, e_2, _b;
        var _c;
        var price = __assign({}, service.price);
        price.amount = 0;
        try {
            for (var _d = __values(Object.keys(state)), _e = _d.next(); !_e.done; _e = _d.next()) {
                var _f = __read(_e.value, 1), passengerId = _f[0];
                try {
                    for (var _g = (e_2 = void 0, __values(Object.keys(state[passengerId]))), _h = _g.next(); !_h.done; _h = _g.next()) {
                        var _j = __read(_h.value, 1), segmentId = _j[0];
                        if ((_c = state[passengerId][segmentId]) === null || _c === void 0 ? void 0 : _c.selected) {
                            price.amount += service.price.amount;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return price;
    }, [state]);
    return {
        totalPrice: totalPrice,
        minPrice: service.price
    };
};
