import { __awaiter, __generator, __read, __values } from "tslib";
import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { getSegments, getTabs, seatMapAdapter } from '../../utils';
import { initI18n, MOBILE_MIN_WIDTH, scrollTo, TABLET_MIDDLE_WIDTH } from '../../../utils';
import { useTranslation } from 'react-i18next';
import SeatMapServiceContent from './Content/SeatMapServiceContent';
import { useTheme } from '../../../theme';
import Passengers from '../Passengers/Passengers';
import SeatsInfo from '../SeatsInfo/SeatsInfo';
import cn from 'classnames';
import { isSeatOccupied } from '../SeatMap/Seat/utils';
import { ModeContext } from '../../../context';
import { Mode } from '../../../types';
import SeatsRecommendation from '../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import SeatsRecommendPopup from '../SeatsRecommend/SeatsRecommendPopup/SeatsRecommendPopup';
import SegmentTabs from '../../../SegmentTabs/SegmentTabs';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType, SeatMapPermissions } from '@websky/graphql';
import { isFreeSeat } from '../SeatMap/utils';
import { useToggleable } from '../../../hooks';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import DoubleSeat from '../Passengers/DoubleSeat/DoubleSeat';
import { useExtraSeat } from '../../hooks';
import { OverrideComponent } from '../../../renderProps';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
initI18n('SeatMap');
var Component = function (props) {
    var _a;
    var _b, _c, _d, _e, _f;
    var _g = __read(React.useState(0), 2), segmentIndex = _g[0], setSegment = _g[1];
    var preselectedIndex = props.passengers.findIndex(function (passenger) { return props.preSelectedPassenger === +passenger.id; });
    var _h = useToggleable(false), hasFreeSeats = _h.isOpen, setHasFreeSeats = _h.open;
    var _j = useToggleable(false), hasSeatsWithAnimals = _j.isOpen, setHasSeatsWithAnimals = _j.setOpen;
    var _k = __read(React.useState(preselectedIndex !== -1 ? preselectedIndex : 0), 2), passengerIndex = _k[0], setPassenger = _k[1];
    var seatMapRef = React.createRef();
    var mode = useContext(ModeContext);
    var t = useTranslation('SeatMap').t;
    var filteredPassengers = props.passengers.filter(function (passenger) { return !(passenger.type === AviaPassengerType.INF); }), passengerId = filteredPassengers[passengerIndex].id;
    var isReturnFlight = ((_c = (_b = props.segments) === null || _b === void 0 ? void 0 : _b[segmentIndex]) === null || _c === void 0 ? void 0 : _c.groupId) === '1';
    var amenities = (_e = (_d = props.segments) === null || _d === void 0 ? void 0 : _d[segmentIndex]) === null || _e === void 0 ? void 0 : _e.amenities;
    var segments = React.useMemo(function () {
        var segmentId = segmentIndex.toString();
        var currentPassenger = props.passengers.find(function (passenger) { return passenger.id === passengerId; });
        // collect passengers seats by segment, so we can find out if double seats are selected
        // key is the seat number, value is the seats themselves
        var selectedSeatsBySegmentMap = new Map();
        props.passengers.forEach(function (passenger) {
            var _a;
            if ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length) {
                var seatsBySegment_1 = passenger.seats.filter(function (seat) { return seat.segmentId === segmentId; });
                seatsBySegment_1.forEach(function (seat) {
                    selectedSeatsBySegmentMap.set(seat.number, seatsBySegment_1);
                });
            }
        });
        return getSegments(seatMapAdapter(props.seatMaps), currentPassenger, props.passengers, selectedSeatsBySegmentMap, amenities, props.isNewOrder);
    }, [props.seatMaps, props.passengers, props.isNewOrder, passengerId, segmentIndex]);
    var servicesCount = (_f = segments[segmentIndex].services) === null || _f === void 0 ? void 0 : _f.length;
    var _l = __read(React.useMemo(function () {
        var _a, _b, _c;
        var allServicesMap = {};
        var canAddedServices = {};
        var seatServices = (_c = (_b = (_a = props.seatMaps.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; })) !== null && _c !== void 0 ? _c : [];
        seatServices.forEach(function (service) {
            service.allowedSegments.forEach(function (allowedSegments) {
                allowedSegments.forEach(function (allowedSegment) {
                    if (!allServicesMap.hasOwnProperty(allowedSegment)) {
                        allServicesMap[allowedSegment] = {};
                        canAddedServices[allowedSegment] = {};
                    }
                    service.allowedPassengers.forEach(function (allowedPassengers) {
                        allowedPassengers.forEach(function (allowedPassenger) {
                            if (!allServicesMap[allowedSegment].hasOwnProperty(allowedPassenger)) {
                                allServicesMap[allowedSegment][allowedPassenger] = {};
                                canAddedServices[allowedSegment][allowedPassenger] = {};
                            }
                            if (service.canBeAdded) {
                                canAddedServices[allowedSegment][allowedPassenger][service.id] = service;
                            }
                            allServicesMap[allowedSegment][allowedPassenger][service.id] = service;
                        });
                    });
                });
            });
        });
        return [canAddedServices, allServicesMap];
    }, [props.seatMaps]), 2), servicesMap = _l[0], allServicesMap = _l[1];
    var theme = useTheme('SeatMap').ComponentStyles;
    React.useEffect(function () {
        scrollTo(0);
    }, []);
    var isTablet = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    var onCancel = React.useCallback(function (passengerId) {
        var _a;
        var actualPassengerId = passengerId !== null && passengerId !== void 0 ? passengerId : passengerIndex.toString();
        props.onSelect((_a = filteredPassengers[actualPassengerId]) === null || _a === void 0 ? void 0 : _a.id, [], segmentIndex);
    }, [props.onSelect, segmentIndex, passengerIndex]);
    var segmentTabs = React.useMemo(function () {
        return getTabs(segments, props.passengers, servicesMap).map(function (item) {
            var value = item.value, header = item.header, isAvailable = item.isAvailable, seatsCount = item.seatsCount;
            return {
                id: value.toString(),
                title: header,
                isDisabled: !isAvailable,
                countSeatsSelected: seatsCount
            };
        });
    }, [props.passengers]);
    var selectNextPassengerWithoutSeat = function () {
        var currentSegmentId = segments[segmentIndex].segmentInfo.id;
        var nextPassengerWithoutSeat = filteredPassengers
            .filter(function (passenger) { return passenger.id !== passengerId; })
            .find(function (passenger) { return !passenger.seats || !passenger.seats.some(function (seat) { return seat.segmentId === currentSegmentId; }); });
        if (nextPassengerWithoutSeat) {
            var nextPassengerWithoutSeatIndex = filteredPassengers.findIndex(function (passenger) { return nextPassengerWithoutSeat.id === passenger.id; });
            setPassenger(nextPassengerWithoutSeatIndex);
        }
    };
    var onSelectNextFlight = React.useCallback(function () {
        if (segmentIndex + 1 < props.seatMaps.segments.length) {
            setSegment(segmentIndex + 1);
            setPassenger(0);
        }
    }, [segmentIndex, props.seatMaps.segments]);
    var onSelectPrevFlight = React.useCallback(function () {
        if (segmentIndex > 0) {
            setSegment(segmentIndex - 1);
            setPassenger(0);
        }
    }, [segmentIndex]);
    var onClear = React.useCallback(function () {
        props.clearOnSegment(segmentIndex);
    }, [segmentIndex, props.clearOnSegment, props.passengers]);
    var onSelect = React.useCallback(function (seats, passengerId) { return __awaiter(void 0, void 0, void 0, function () {
        var currentPassenger, isOccupied;
        return __generator(this, function (_a) {
            currentPassenger = filteredPassengers.find(function (passenger) { return passenger.id === passengerId; });
            isOccupied = seats.some(function (seat) {
                return isSeatOccupied(seat, currentPassenger, segments[segmentIndex].segmentInfo.id);
            });
            if (!isOccupied) {
                props.onSelect(passengerId, seats, segmentIndex);
                selectNextPassengerWithoutSeat();
            }
            else {
                // onCancel();
                props.onCancelSeat(passengerId, seats, segmentIndex);
            }
            return [2 /*return*/];
        });
    }); }, [props.onSelect, segmentIndex, filteredPassengers]);
    var segmentId = segments[segmentIndex].segmentInfo.id;
    var hideControls = React.useMemo(function () { return segments.every(function (segment) { return segment.isSeatsAvailableInCheckinOnly; }) && mode !== Mode.Checkin; }, [segments]);
    /**
     * Объединил получение инфы о наличии мест с животными или бесплатными местами
     * если на сегменте находятся оба таких места, цикл прекращается
     * в противном случае, проверят до конца
     */
    React.useEffect(function () {
        var e_1, _a, e_2, _b, e_3, _c;
        var _d;
        try {
            for (var _e = __values(segments[segmentIndex].decks), _f = _e.next(); !_f.done; _f = _e.next()) {
                var deck = _f.value;
                try {
                    for (var _g = (e_2 = void 0, __values(deck.rows)), _h = _g.next(); !_h.done; _h = _g.next()) {
                        var row = _h.value;
                        try {
                            for (var _j = (e_3 = void 0, __values(row.seats)), _k = _j.next(); !_k.done; _k = _j.next()) {
                                var seat = _k.value;
                                if (hasSeatsWithAnimals && hasFreeSeats) {
                                    break;
                                }
                                if (!hasSeatsWithAnimals && ((_d = seat.permissions) === null || _d === void 0 ? void 0 : _d.includes(SeatMapPermissions.WithAnimals))) {
                                    setHasSeatsWithAnimals(true);
                                }
                                if (!hasFreeSeats && isFreeSeat(seat)) {
                                    setHasFreeSeats();
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }, [segments, segmentIndex, hasFreeSeats]);
    React.useEffect(function () {
        if (isTablet) {
            setPassenger(0);
        }
    }, [segmentIndex]);
    var hasSelectedSeats = useMemo(function () {
        var _a, _b;
        return !!((_b = (_a = props.passengers.find(function (passenger) { return passenger.id === passengerId; })) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.length);
    }, [props.passengers, passengerId]);
    var passengersIdsWithExtraSeats = useMemo(function () {
        var filteredPassengers = isTablet
            ? props.passengers
            : props.passengers.filter(function (passenger) { return passenger.id === passengerId; });
        return filteredPassengers
            .filter(function (passenger) {
            var _a;
            return (_a = props.selectedExtraSeats) === null || _a === void 0 ? void 0 : _a.find(function (extraSeat) {
                return extraSeat.passengerId === passenger.id && extraSeat.segmentIds.includes(segmentIndex.toString());
            });
        })
            .map(function (passenger) { return passenger.id; });
    }, [props.passengers, props.selectedExtraSeats, passengerId, segmentIndex, isTablet]);
    var _m = useExtraSeat(segments, passengerId, segmentIndex), showDoubleSeats = _m.showDoubleSeats, extraSeatService = _m.extraSeatService, toggleExtraSeatService = _m.toggleExtraSeatService;
    var toggleExtraSeatServiceHandler = useCallback(function (active) {
        if (hasSelectedSeats) {
            props.onPassengerClear(passengerId);
        }
        toggleExtraSeatService(active, passengerId);
    }, [passengerId, hasSelectedSeats, props.onPassengerClear]);
    var renderDoubleSeats = React.useCallback(function () {
        if (showDoubleSeats) {
            return (React.createElement(DoubleSeat, { description: !isTablet ? extraSeatService.description || '' : null, isActive: passengersIdsWithExtraSeats.length > 0, hasSeatsByPassenger: hasSelectedSeats, showInfo: segments.length > 1, toggleExtraSeat: toggleExtraSeatServiceHandler }));
        }
        return null;
    }, [
        showDoubleSeats,
        extraSeatService,
        passengersIdsWithExtraSeats,
        toggleExtraSeatService,
        hasSelectedSeats,
        segments,
        isTablet
    ]);
    var onChangePassenger = function (passengerId) {
        var _a;
        var currentIndex = filteredPassengers.findIndex(function (passenger) { return passenger.id === passengerId; });
        if (currentIndex !== -1) {
            setPassenger(currentIndex);
        }
        else {
            var dIndex_1 = +passengerId - +filteredPassengers[passengerIndex].id;
            var nextPassengers = filteredPassengers.filter(function (passenger) {
                return dIndex_1 > 0 ? passenger.id >= passengerId : passenger.id <= passengerId;
            });
            var nextPassengerId_1 = (_a = nextPassengers[dIndex_1 > 0 ? 0 : nextPassengers.length - 1]) === null || _a === void 0 ? void 0 : _a.id;
            var nextPassengerIndex = nextPassengerId_1
                ? filteredPassengers.findIndex(function (passenger) { return passenger.id === nextPassengerId_1; })
                : 0;
            setPassenger(nextPassengerIndex);
        }
    };
    var renderPassengers = React.useCallback(function () {
        var _a;
        return (React.createElement(OverrideComponent, { componentProps: {
                hideDisclaimer: hideControls,
                hideNextButton: hideControls,
                hidePassengers: hideControls,
                services: allServicesMap,
                onProceed: props.onProceed,
                seatMapRef: seatMapRef,
                passengers: props.passengers,
                segmentId: segmentId,
                passengerId: passengerId,
                onSelectNextFlight: onSelectNextFlight,
                segmentIndex: segmentIndex,
                segments: segments,
                hasSeatsWithAnimals: hasSeatsWithAnimals,
                onChange: onChangePassenger,
                onCancel: onCancel,
                onClear: onClear,
                renderDoubleSeats: renderDoubleSeats,
                hasSelectedExtraSeats: !!props.selectedExtraSeats.length,
                passengersIdsWithExtraSeats: passengersIdsWithExtraSeats,
                toggleExtraSeat: showDoubleSeats ? toggleExtraSeatServiceHandler : null,
                isNewOrder: props.isNewOrder,
                isDialog: !!((_a = props.dialogRef) === null || _a === void 0 ? void 0 : _a.current)
            }, component: {
                SeatMapPassengers: Passengers
            } }));
    }, [
        props.passengers,
        segmentIndex,
        passengerId,
        hasSeatsWithAnimals,
        renderDoubleSeats,
        passengersIdsWithExtraSeats,
        toggleExtraSeatServiceHandler,
        props.selectedExtraSeats,
        props.isNewOrder,
        props.dialogRef
    ]);
    var renderSeatsInfo = React.useCallback(function () {
        if (segments[segmentIndex].services) {
            return props.renderSeatsInfo ? (props.renderSeatsInfo(segments[segmentIndex].services, segmentIndex)) : (React.createElement(SeatsInfo, { hasFreeSeats: hasFreeSeats, seatsInfo: segments[segmentIndex].services, amenities: amenities, segmentId: segmentIndex.toString(), services: servicesMap, passengerId: passengerId }));
        }
        return null;
    }, [segments, segmentIndex, hasFreeSeats, props.renderSeatsInfo, passengerId]);
    var renderTabs = React.useCallback(function () { return (React.createElement(SegmentTabs, { classNameTab: theme.segmentTabs_tab, activeClassName: theme.segmentTabs_active, segmentsClassName: theme.segmentTabs, title: t('Choose your seat'), activeSegment: segmentIndex.toString(), segments: segmentTabs, onSegmentClick: function (segment) { return setSegment(parseInt(segment)); }, sticky: true, onClose: props.onClose, center: true })); }, [segmentTabs, segmentIndex, setSegment]);
    var seatMapRecommendEnabled = React.useMemo(function () {
        return props.seatRecommendEnabled && !hideControls;
    }, [props.seatRecommendEnabled, hideControls]);
    useEffect(function () {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.SeatsList, { segmentId: segmentIndex.toString() });
    }, [segmentIndex]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, renderTabs()),
        React.createElement("div", { className: cn(theme.wrapper, props.className, (_a = {},
                _a[theme.wrapper_threeServices] = servicesCount === 3,
                _a[theme.wrapper_fourServices] = servicesCount === 4,
                _a)) },
            React.createElement(SeatsRecommendation, { orderId: props.orderId, segments: props.segments, travellers: props.travellers, fillOrder: props.fillOrder, runServiceRequest: props.runServiceRequest, segmentIndex: segmentIndex, passengerId: passengerId, isEnabled: seatMapRecommendEnabled, setSeat: props.setSeatCallback, confirmRecommendedSeatsWithoutDialog: props.confirmRecommendedSeatsWithoutDialog, seatMap: props.seatMaps },
                React.createElement(OverrideComponent, { componentProps: {
                        allSeatServices: allServicesMap,
                        services: servicesMap,
                        fullScreen: props.fullScreen,
                        segments: segments,
                        onSelect: onSelect,
                        passengers: filteredPassengers,
                        seatMapRef: seatMapRef,
                        onSelectNextFlight: onSelectNextFlight,
                        passengerId: passengerId,
                        segmentIndex: segmentIndex,
                        swipeBetweenSegments: true,
                        activeDoubleSeats: passengersIdsWithExtraSeats.includes(passengerId),
                        renderPassengers: renderPassengers,
                        renderSeatsInfo: renderSeatsInfo,
                        onProceed: props.onProceed,
                        onBack: onSelectPrevFlight,
                        onClear: onClear,
                        dialogRef: props.dialogRef,
                        isReturnFlight: isReturnFlight
                    }, component: { SeatMapServiceContent: SeatMapServiceContent } }),
                React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
                    React.createElement(SeatsRecommendPopup, { passengerId: passengerId, segmentId: segmentIndex, services: servicesMap, setPassenger: setPassenger, passengers: filteredPassengers }))))));
};
export default Component;
