import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { useConsents } from './Consents.hook';
import Checkbox from './Checkbox/Checkbox';
initI18n('Consents');
var Consents = function (_a) {
    var children = _a.children;
    var t = useTranslation('Consents').t;
    var theme = useTheme('Consents').Consents;
    var model = useConsents();
    var tooltipLabel = t('Checkout:Please, read the rules of service and accept it');
    return (React.createElement(React.Fragment, null,
        (model.isNeedToShowRules || model.isNeedToShowTransferRules) && (React.createElement("div", { className: theme.container },
            model.isNeedToShowRules && (React.createElement("div", { className: theme.consent },
                React.createElement(Checkbox, { label: model.rules, tooltipLabel: tooltipLabel, isChecked: model.commonConsentsCheckbox.isChecked, isTooltipOpen: model.commonConsentsCheckbox.isTooltipOpen, onChange: model.commonConsentsCheckbox.onChange, ref: model.commonConsentsCheckbox.checkboxRef }))),
            model.isNeedToShowTransferRules && (React.createElement("div", { className: theme.consent },
                React.createElement(Checkbox, { label: model.transferRules, tooltipLabel: tooltipLabel, isChecked: model.transferConsentsCheckbox.isChecked, isTooltipOpen: model.transferConsentsCheckbox.isTooltipOpen, onChange: model.transferConsentsCheckbox.onChange, ref: model.transferConsentsCheckbox.checkboxRef }))))),
        children(model)));
};
export default Consents;
