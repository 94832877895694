import { __awaiter, __generator, __read, __values } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import { TransferService } from '../../../Transfer';
import Dialog from '../../../Service/components/Dialog/Dialog';
import { useToggleable } from '../../../hooks';
import CheckoutAdditionalService from '../../index';
import SegmentTabs from '../../../SegmentTabs/SegmentTabs';
import FullScreenDialogHeader from '../../../FullscreenDialog/components/Header/Header';
import { ThemeProvider, useTheme } from '../../../theme';
import Toolbar from '../../../Toolbar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getGroundTransportationServices, getOrder } from '../../../Checkout/store/order/selectors';
import { useRenderers } from '../../../renderProps';
import { getMinPrice, getTravellersMap, initI18n, useIsMobile } from '../../../utils';
import { fillOrder } from '../../../Checkout/store/order/actions';
import TransferSummary from '../../../ServiceSummary/services/TransferSummary';
import { useCart } from '../../../Checkout/components/Checkout/Stepbar/hooks';
initI18n('Transfer');
var Transfer = function (props) {
    var _a;
    var t = useTranslation('Checkout').t;
    var _b = useToggleable(false), isOpen = _b.isOpen, close = _b.close, open = _b.open;
    var order = useSelector(getOrder);
    var _c = __read(useState(order.flight.segments[0].segment.id), 2), activeSegment = _c[0], setActiveSegment = _c[1];
    var _d = __read(useState(null), 2), servicesToSave = _d[0], setServicesToSave = _d[1];
    var _e = __read(useState(false), 2), initialStateChanged = _e[0], setInitialStateChanged = _e[1];
    var dispatch = useDispatch();
    var transferServices = useSelector(getGroundTransportationServices);
    var transferServicesIds = transferServices.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var isSelected = useMemo(function () {
        var e_1, _a;
        var _b, _c, _d;
        var transferServicesIds = transferServices.map(function (_a) {
            var id = _a.id;
            return id;
        });
        try {
            for (var _e = __values(order.travellers), _f = _e.next(); !_f.done; _f = _e.next()) {
                var traveller = _f.value;
                if ((_d = (_c = (_b = traveller.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.some(function (service) {
                    return transferServicesIds.includes(service.serviceId);
                })) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    }, [order.travellers, transferServices]);
    var orderPriceToPay = useMemo(function () {
        var e_2, _a, e_3, _b;
        var _c, _d, _e, _f, _g, _h, _j;
        var priceToPay = {
            amount: 0,
            currency: (_d = (_c = order === null || order === void 0 ? void 0 : order.price) === null || _c === void 0 ? void 0 : _c.total) === null || _d === void 0 ? void 0 : _d.currency
        };
        try {
            for (var _k = __values(order.travellers), _l = _k.next(); !_l.done; _l = _k.next()) {
                var traveller = _l.value;
                var _loop_1 = function (service) {
                    if (transferServicesIds.includes(service.serviceId)) {
                        var orderService = (_j = (_h = (_g = order.additionalServices) === null || _g === void 0 ? void 0 : _g.gdsServices) === null || _h === void 0 ? void 0 : _h.services) === null || _j === void 0 ? void 0 : _j.find(function (orderService) { return orderService.id === service.serviceId; });
                        if (orderService) {
                            priceToPay.amount += service.segmentIds.length * orderService.price.amount;
                        }
                    }
                };
                try {
                    for (var _m = (e_3 = void 0, __values(((_f = (_e = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _e === void 0 ? void 0 : _e.gdsServices) === null || _f === void 0 ? void 0 : _f.services) || [])), _o = _m.next(); !_o.done; _o = _m.next()) {
                        var service = _o.value;
                        _loop_1(service);
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_o && !_o.done && (_b = _m.return)) _b.call(_m);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_l && !_l.done && (_a = _k.return)) _a.call(_k);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return priceToPay;
    }, [transferServices, servicesToSave, order.travellers]);
    var minPrice = getMinPrice(transferServices, function (transferService) { return transferService.price; });
    var _f = __read(useSaveOrderServicesMutation(), 2), saveServices = _f[0], loading = _f[1].loading;
    var css = useTheme('CheckoutAdditionalService').Transfer;
    var handleSubmit = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!initialStateChanged) {
                        close();
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, saveServices({
                            variables: {
                                params: servicesToSave
                            }
                        })];
                case 1:
                    result = _a.sent();
                    dispatch(fillOrder(result.data.SaveOrderServices));
                    close();
                    return [2 /*return*/];
            }
        });
    }); }, [servicesToSave, initialStateChanged]);
    var CustomCheckoutAdditionalService = useRenderers().CheckoutAdditionalService;
    var ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    var buttons = useMemo(function () {
        if (isSelected) {
            return [
                {
                    text: t('Change'),
                    action: open,
                    isLoading: loading,
                    buttonType: 'change',
                    variant: 'text'
                }
            ];
        }
        else {
            return [
                {
                    text: t('Add'),
                    action: open,
                    isLoading: loading,
                    buttonType: 'change',
                    variant: 'outline'
                }
            ];
        }
    }, [isSelected, loading]);
    var priceToPayBeforeSave = useMemo(function () {
        var e_4, _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var priceToPay = {
            amount: 0,
            currency: (_c = (_b = order === null || order === void 0 ? void 0 : order.price) === null || _b === void 0 ? void 0 : _b.total) === null || _c === void 0 ? void 0 : _c.currency
        };
        var _loop_2 = function (travellerServices) {
            var e_5, _q;
            var _loop_3 = function (service) {
                var relatedOrderService = (_j = (_h = (_g = order.additionalServices) === null || _g === void 0 ? void 0 : _g.gdsServices) === null || _h === void 0 ? void 0 : _h.services) === null || _j === void 0 ? void 0 : _j.find(function (relatedService) { return relatedService.id === service.serviceId; });
                var relatedTraveller = order.travellers.find(function (traveller) { return traveller.id === travellerServices.travellerId; });
                var relatedTravellerService = (_m = (_l = (_k = relatedTraveller === null || relatedTraveller === void 0 ? void 0 : relatedTraveller.services) === null || _k === void 0 ? void 0 : _k.gdsServices) === null || _l === void 0 ? void 0 : _l.services) === null || _m === void 0 ? void 0 : _m.find(function (travellerService) { return travellerService.serviceId === relatedOrderService.id; });
                priceToPay.amount += relatedOrderService.price.amount * service.count;
                if (relatedTravellerService) {
                    priceToPay.amount -= relatedOrderService.price.amount * relatedTravellerService.confirmedCount;
                }
            };
            try {
                for (var _r = (e_5 = void 0, __values((_f = (_e = travellerServices === null || travellerServices === void 0 ? void 0 : travellerServices.gdsServices) === null || _e === void 0 ? void 0 : _e.setServices) !== null && _f !== void 0 ? _f : [])), _s = _r.next(); !_s.done; _s = _r.next()) {
                    var service = _s.value;
                    _loop_3(service);
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (_s && !_s.done && (_q = _r.return)) _q.call(_r);
                }
                finally { if (e_5) throw e_5.error; }
            }
        };
        try {
            for (var _o = __values((_d = servicesToSave === null || servicesToSave === void 0 ? void 0 : servicesToSave.services) !== null && _d !== void 0 ? _d : []), _p = _o.next(); !_p.done; _p = _o.next()) {
                var travellerServices = _p.value;
                _loop_2(travellerServices);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_p && !_p.done && (_a = _o.return)) _a.call(_o);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return priceToPay;
    }, [servicesToSave]);
    var isMobile = useIsMobile();
    var onDeleteItem = useCart().onDeleteItem;
    return (React.createElement(React.Fragment, null,
        React.createElement(TransferSummary, { flight: order.flight, travellers: order.travellers, services: order.additionalServices, travellersById: getTravellersMap(order.travellers), onServiceDelete: onDeleteItem }, function (summary) { return (React.createElement(ServiceWrapper, { className: css.header, header: t('Transfer'), description: t('Transfer:Cheap and on time to get to your destination.'), isSelected: isSelected, priceTotal: orderPriceToPay, isLoading: loading, priceFrom: minPrice, addClick: open, buttons: buttons }, summary)); }),
        isOpen && (React.createElement(Dialog, { isOpen: isOpen, dialogContentClassName: css.dialogContent, dialogType: 'fullscreen', hideHeader: true, onClose: close, content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: css.header },
                    React.createElement(FullScreenDialogHeader, { hideTitle: isMobile, headerClassName: css.headerInner, onClose: close, title: t('Transfer:Choose transfer'), renderBeforeCloseButton: function () { return (React.createElement(ThemeProvider, { value: {
                                SegmentTabs: {
                                    SegmentTabs: {
                                        inner: css.segmentTabs
                                    }
                                }
                            } },
                            React.createElement(SegmentTabs, { title: '', isCompact: isMobile, wrapperClassName: css.segmentsTabsWrapper, activeSegment: activeSegment, onSegmentClick: setActiveSegment, segments: order.flight.segments.map(function (segment) { return ({
                                    id: segment.segment.id,
                                    title: "".concat(segment.segment.departure.airport.city.name, "-").concat(segment.segment.arrival.airport.city.name),
                                    isDisabled: false
                                }); }) }))); } })),
                React.createElement(TransferService, { order: order, onChange: setServicesToSave, onStateChanged: setInitialStateChanged, activeSegment: activeSegment }),
                React.createElement(Toolbar, { totalPrice: priceToPayBeforeSave, isLoading: loading, onButtonClick: handleSubmit })) }))));
};
export default Transfer;
