import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Log } from '../../sentry';
import { isCheckoutStateGuard, isResultsStateGuard } from '../../utils';
var fareGroupCompareTableVariablesFactory = function (state) {
    var _a, _b, _c, _d;
    var variables = {};
    if (!isResultsStateGuard(state) && !isCheckoutStateGuard(state)) {
        Log({
            message: 'CompareFares: undefined state',
            data: state,
            level: 'error'
        });
        return variables;
    }
    if (isResultsStateGuard(state)) {
        variables.orderId = null;
        if (state.legInView) {
            variables.flightId = (_d = (_c = (_b = (_a = state.legInView.pricesForFareGroups[0]) === null || _a === void 0 ? void 0 : _a.prices) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.flight) === null || _d === void 0 ? void 0 : _d.id;
            variables.iata = null;
        }
        else if (state.selectedFares.length) {
            variables.flightId = state.selectedFares[0].flightInfo.id;
            variables.iata = null;
        }
    }
    else if (isCheckoutStateGuard(state)) {
        variables.flightId = null;
        variables.orderId = state.order.id;
        variables.iata = null;
    }
    return variables;
};
export var useCompareFaresModel = function () {
    var state = useSelector(function (state) { return state; });
    var compareTableVariables = useMemo(function () { return fareGroupCompareTableVariablesFactory(state); }, [state]);
    return {
        compareTableVariables: compareTableVariables
    };
};
