import { __assign, __rest } from "tslib";
import * as React from 'react';
import Input from '../../../../Input/Input';
import MaskedInput from 'react-text-mask';
import { Datepicker } from '../../../../Datepicker';
import { Dialog } from '@material-ui/core';
var DateMaskedInput = function (props) {
    var inputRef = props.inputRef, other = __rest(props, ["inputRef"]);
    return (React.createElement(MaskedInput, __assign({}, other, { placeholder: "__.__.____", ref: function (ref) { return inputRef(ref ? ref.inputElement : null); }, guide: false, mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/] })));
};
export var DateField = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Input, { label: props.label, value: props.value, name: props.name, className: props.className, labelClassName: props.labelClassName, labelShrinkClassName: props.labelShrinkClassName, focused: props.focused, TextFieldProps: {
                onChange: props.onChange,
                onBlur: props.onBlur,
                error: !!props.error,
                helperText: props.error,
                disabled: props.disabled,
                type: 'tel'
            }, autoComplete: 'none', shrink: props.shrink, variant: props.variant, inputComponent: DateMaskedInput, absoluteHintPosition: props.absoluteHintPosition }),
        !!props.datePicker && (React.createElement(Dialog, { open: props.datePicker.isOpenDatePicker, onClose: props.datePicker.onCloseDatePicker },
            React.createElement(Datepicker, { openDate: props.datePicker.openDate, selectedDates: props.datePicker.selectedDates, minDate: props.datePicker.minDate, maxDate: props.datePicker.maxDate, onSelect: props.datePicker.onSelect })))));
};
