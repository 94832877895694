import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { Button, SlideBottom } from '../../../../../index';
import SeatInfo from '../../../SeatsInfo/SeatInfo/SeatInfo';
import { getPassengerLabel } from '../../../../utils';
var SeatServiceSlide = function (_a) {
    var _b, _c;
    var _d, _e;
    var seats = _a.seats, passenger = _a.passenger, isOpen = _a.isOpen, isSelected = _a.isSelected, onClose = _a.onClose, onSelect = _a.onSelect, isFreeSeat = _a.isFreeSeat, dataColorNum = _a.dataColorNum;
    var theme = useTheme('SeatMap').SeatServiceSlide;
    var t = useTranslation('SeatMap').t;
    var seatNumbers = seats.map(function (seat) { return seat.number; });
    var onSelectHandler = function () {
        onSelect(seats[0]);
    };
    return (React.createElement(SlideBottom, { isOpen: isOpen, className: theme.serviceInfo__slider, onClose: onClose, header: isSelected ? t('Seat selection') : null },
        React.createElement("div", null,
            React.createElement("div", { className: theme.serviceInfo__seat, "data-color-num": dataColorNum },
                React.createElement(SeatInfo, { dataColorNum: dataColorNum, seatService: seats[0].service, seat: seats[0], isFreeSeat: isFreeSeat }),
                React.createElement("div", { className: theme.serviceInfo__selectable, "data-rfisc": (_e = (_d = seats === null || seats === void 0 ? void 0 : seats[0]) === null || _d === void 0 ? void 0 : _d.service) === null || _e === void 0 ? void 0 : _e.rfisc },
                    React.createElement("div", { className: theme.serviceInfo__passenger }, getPassengerLabel(passenger, t, true)),
                    React.createElement("div", { className: cn(theme.seat, (_b = {},
                            _b[theme.seat_double] = seatNumbers.length > 1,
                            _b)) }, seatNumbers.join(', ')))),
            React.createElement(Button, { variant: "flat", onClick: onSelectHandler, className: cn(theme.single, (_c = {}, _c[theme.single_cancel] = isSelected, _c)) }, isSelected ? t('Cancel') : t('Take this seat')))));
};
export default SeatServiceSlide;
